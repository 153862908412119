import React, { useState, useEffect } from 'react';
import { ajaxFail, removeTurkcLow, isEmpty } from '../GlobalState.js';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';

export default function SablonHavalePanel(props) {

    const [appData, setData] = useState(props.appData);
    const [listData, setListData] = useState([]);
    const [denetimList, setDenetimList] = useState(props.denetimlist);
    const [kontrolorList, setKontrolorList] = useState([]);
    useEffect(() => {
        //fetchDenetimList();
        fetchKontrollers();
        fetchList();

    }, [])


    //const fetchDenetimList = () => {
    //    fetch("denetimlist", {
    //        method: 'GET',
    //        headers: {
    //            'Accept': 'application/json',
    //            'Content-Type': 'application/json'
    //        }
    //    }).then(async response => {
    //        if (!response.ok) {
    //            ajaxFail(response)
    //        }
    //        else {
    //            response.json().then(value => {
    //                setDenetimList(value);
    //            })
    //        }
    //    });
    //}


    const fetchKontrollers = () => {
        fetch("userswithperm", {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify("")
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setKontrolorList(data)
                });
            }
        });

    }


    const fetchList = () => {

        fetch("havalesablonlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    var v = value.filter(x => isEmpty(x.basvuruTipi) || x.basvuruTipi.includes(appData.basvuruTipi));
                    setListData(v);
                })
            }
        });
    }




    const apply = (rec) => {
        rec.id = appData.id;
        var spinner = document.getElementById("spinner");
        if (spinner != null)
            spinner.hidden = false;

        fetch("applyhavalesablon", {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (spinner != null)
                spinner.hidden = true;
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                props.fetchMapping();
            }
        });
    }

    const getMappingName = (rec) => {
        var username = rec.split("=")[0].replace("&", "");
        var k = kontrolorList.find(x => x.userName == username);
        if (k == null) {
            return username + " kullanıcısı bulunamadı !";
        }
        var d = denetimList.find(x => x.id == rec.split("=")[1]);
        return k.name + " " + k.family + "(" + (d == null ? "" : d.name) + ")  "

    }

    const getNames = (rec) => {
        if (denetimList.length > 0 && kontrolorList.length > 0) {
            var mapping = rec.split(",");
            var ret = mapping.map(x => getMappingName(x));
            var sire = -1;
            for (var i = 0; i < ret.length; i++) {
                if (mapping[i].includes("&") == false)
                    sire++;
                ret[i] = sire + ": " + ret[i]

            }
            return ret.join(" , ")

        }
        return rec;

    }

    const filterColumn = (event) => {
        const target = event.target;
        var c = Number(target.getAttribute('name'));
        var value = target.value;

        $("#myTable tr").filter(function () {
            var td = $(this).children().eq(c);
            if (isEmpty(value) == false) {
                td.attr('style', 'background: #fff8dd !important');
                var t1 = removeTurkcLow(td.text());
                var t2 = removeTurkcLow(value);
                if (t1.indexOf(t2) == -1)
                    return $(this).toggle(false);
            }
            else
                td.removeAttr('style');
            return $(this).toggle(true);
        });
    }

    const renderTable = (tabledata) => {
        var mytable =

            <table className='table  table-sm' aria-labelledby="tabelLabel">
                <thead>
                    <th width="5%">
                        <input type="text" className="form-control form-control-sm" placeholder="" name="0" onChange={filterColumn} />
                    </th>
                    <th width="10%">
                        <input type="text" className="form-control form-control-sm" placeholder="" name="1" onChange={filterColumn} />
                    </th>
                    <th width="75%">
                        <input type="text" className="form-control form-control-sm" placeholder="" name="2" onChange={filterColumn} />
                    </th>
                    <th width="10%">
                        <div id="spinner" hidden={true} className="spinner-border spinner-border-sm text-muted" ></div>
                    </th>
                </thead>
                <tbody id="myTable">


                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td width="5%">{apprec.id}</td>
                            <td width="20%">
                                {apprec.sablonName}
                            </td>
                            {/*<td>*/}
                            {/*    {apprec.basvuruTipi}*/}
                            {/*</td>*/}
                            <td width="60%">
                                {getNames(apprec.mapping)}
                            </td>

                            <td className="text-end">
                                <button onClick={() => apply(apprec)} className="btn btn-primary btn-sm text-decoration-none me-1">Uygula</button>
                            </td>
                        </tr>
                    )}

                </tbody>
            </table>

        return (mytable);
    }








    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="  mt-5 pt-3">
            
            <button className="btn btn-primary btn-sm mb-1 shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                Havale Şablonları
            </button>
            <div className="row border-top pt-1 collapse" id="collapseExample">
                <div className="col-12 table-responsive mainpagescroll">
                    {listTable}
                </div>
            </div>
        </div >

    );


}