import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js'

import { useGlobalState, isEmpty, showErrorMessage } from '../GlobalState.js';
import Apply2 from "./Apply2.js";
import * as UserPermConstants from '../User/UserPermConstants';
import { ajaxFail } from '../GlobalState.js';


export default function Apply1(props) {

    var [globalData, setGlobalData] = useGlobalState('globalData');




    const [appTypeGroups, setappTypeGroups] = useState([]);
    const [appTypes, setappTypes] = useState([]);
    const [userData, setuserData] = useGlobalState('userData');

    const [userName, setuserName] = useGlobalState('userName');
    const [role, setRole] = useGlobalState('role');
    var applicationdata = props.appData != null ? props.appData : {};


    useEffect(() => {
        if ((userData != null && userData.edveletConfirmed == true) || role.indexOf(UserPermConstants.Edevletsiz_Basvuru) != -1)
            getAppTypes();


    }, [])

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked.toString() : target.value;
        applicationdata[target.name] = value;
    }

    const handleGroupChange = (event) => {
        const target = event.target;
        const value = target.value;

        var c = document.getElementById("basvurutipleri");
        c.innerHTML = "";
        if (value == -1) return;
        for (var i = 0; i < appTypes.length; i++) {
            if (appTypes[i].grup != "" && appTypes[i].grup != null && appTypes[i].grup != value)
                continue;
            if (appTypes[i].active == true && (appTypes[i].hide != true || role.indexOf(UserPermConstants.Gizli_Basvuru_Gorme) > -1)) {
                var opt = document.createElement('option');
                opt.value = appTypes[i].name;
                opt.innerHTML = appTypes[i].name;
                if (applicationdata.basvuruTipi == appTypes[i].name)
                    opt.selected = true;
                c.appendChild(opt);

            }
            if (applicationdata.basvuruTipi == null) c.selectedIndex = -1;

        }
    }

    const fetchAppTypeGrupList = () => {
        fetch("apptypegroupslist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    var c = document.getElementById("basvurutipleri_snipper");
                    if (c != null)
                        c.hidden = true;
                    setappTypeGroups(value);

                })
            }
        });
    }

    const getAppTypes = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    value = value.filter(x => x.isadimi != true);

                    if (role.indexOf(UserPermConstants.Gizli_Basvuru_Gorme) > -1)
                        setappTypes(value);
                    else
                        setappTypes(value.filter(x => x.hide != true));
                    fetchAppTypeGrupList();
                })
            }
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        var check1 = document.getElementById("applyAgrement");
        var check2 = document.getElementById("applyKVKK");
        if (check1 != null && check1.checked == false) {
            showErrorMessage("Başvuru sözleşmesi kabul edilmelidir.");
            return;
        }
        if (check2 != null && check2.checked == false) {
            showErrorMessage("KVKK bilgilendirilmesi kabul edilmelidir.");
            return;
        }

        applicationdata.basvuruYapan = userName;
        window.selectedAppType = appTypes.find(x => applicationdata.basvuruTipi == x.name);


        props.changeComponent(<Apply2 applicationdata={applicationdata} changeComponent={props.changeComponent} />)

    }

    if (userData == null) {
        return <><div className="row mt-3" >
            <div className="col-12 text-danger text-center  " >
                Kullanıcı bilgilerine erişilemedi.
            </div>
        </div>
        </>
    }


    if (userData.edveletConfirmed != true && role.indexOf(UserPermConstants.Edevletsiz_Basvuru) == -1) {
        return <><div className="row mt-3" >
            <div className="col-12 text-danger text-center  " >
                Başvuru yapabilmek için e-Devlet Kapısı üzerinden giriş yapılmalıdır.
            </div>
        </div>

            <div class="row mt-5">
                <div class="col-md-12 col-lg-4"> </div>
                <div className="col-12 col-lg-4 text-center ">
                    <a className="btn btn-sm btn-danger text-decoration-none w-100 rounded-3  mt-3" href="edevletlogin">  <img src={require('../Dashboard/edevletlogo.png').default} style={{ width: "28px", height: "28px" }} /> <b>e-Devlet Kapısı ile Giriş Yap</b></a>
                </div>
            </div>
        </>
    }





    return <div  >
        <div className="row" >
            <div className="  col-md-2 col-12 text-primary  " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    1. Başvuru Tipi

                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    2. Konum
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    3. Müellifler
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    4. Diğer Sorular
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    5. Dosyalar
                </div>
            </div>
            <div className=" col-md-2 col-12     " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    6. Onay
                </div>
            </div>
        </div>
        <div className="row m-3" >
            <div className="col-12" >
                <div className="  align-items-center justify-content-center p-1" >
                    <form action="submitapplication" className="mt-3" method="POST" onSubmit={onSubmit}>

                        <div>
                            <div className="mt-1 row " >
                                <div className="col-12 col-md-4">
                                    <label className="form-label">Basvuru Grubu *
                                        <div class="spinner-border spinner-border-sm ps-1" id="basvurutipleri_snipper"></div>

                                    </label>
                                    <select className="form-select form-select-sm" name="grup" required={true} onChange={handleGroupChange} >
                                        <option value={-1}> </option>
                                        {appTypeGroups.map(x => <option value={x.id}>{x.name}</option>)}
                                    </select>
                                </div>

                            </div>



                            <div className="mt-4 row mb-3" >
                                <div className="col-12 col-md-4">
                                    <label className="form-label">Başvuru Tipi *


                                    </label>
                                    <select className="form-select form-select-sm" id="basvurutipleri" name="basvuruTipi" required={true} onChange={handleInputChange}>
                                    </select>
                                </div>
                            </div>

                            {globalData != null && <div className="mt-5"> {isEmpty(globalData.applyKVKK) == false && <div className=" row mt-2"  >
                                <div className="col-12">
                                    <input class="form-check-input me-1" type="checkbox" id="applyKVKK" name="applyKVKK" />
                                    <label class="form-check-label" dangerouslySetInnerHTML={{ __html: globalData.applyKVKK }}></label>
                                </div>
                            </div>}
                                {isEmpty(globalData.applyAgrement) == false && < div className=" row mt-2">
                                    <div className="col-12">
                                        <input class="form-check-input me-1" type="checkbox" id="applyAgrement" name="applyAgrement" />
                                        <label class="form-check-label" dangerouslySetInnerHTML={{ __html: globalData.applyAgrement }}></label>
                                    </div>
                                </div>}</div>}



                            <div className="row mt-3 border-top pt-2" >
                                <div className="col-12 col-md-12 text-end">
                                    <button type="submit" name="actionSave" value="Save" className="btn btn-sm btn-primary mb-2 ">Devam</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
}



