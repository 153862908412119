import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useGlobalState, isEmpty, showSuccessMessage } from '../GlobalState.js';
import { ajaxFail, removeTurkcLow, showConfirmMessage } from '../GlobalState.js';
import $ from 'jquery';
import FileSettingDetaile from './FileSettingDetaile.js';


export default function FileSettingList(props) {
    var postdata = {};
    postdata["FileType"] = "";
    const [listData, setListData] = useState([]);
    const [fileGurups, setFileGrupList] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    var multiselectRef = React.createRef();
    var sortField = null;
    const [role, setrol] = useGlobalState('role');
    const [roles, setRoles] = useState([]);
    const [sortDirection, setSortDirection] = useState({});


    const fetchRoles = () => {
        fetch("getrolelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setRoles(value)
                })
            }
        });

    }


    const updatefilename = (rec) => {

        fetch("updatefilename", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    showSuccessMessage("Bilgiler Güncellenmiştir.")
                    setListData(value);
                })
            }
        });
    }


    const sortList = (fildname) => {
        sortField = fildname;
        if (sortDirection[sortField] == null || sortDirection[sortField] == "ZA") {
            sortDirection[sortField] = "AZ";
        }
        else {
            sortDirection[sortField] = "ZA";
        }

        var temp = [...listData]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setListData(temp);
    }


    const myCompare = (a, b) => {
        var av = a[sortField];
        var bv = b[sortField];
        var ret = 1;
        if (sortDirection[sortField] == "ZA")
            ret = -1;

        if (av == null && bv != null)
            return -1 * ret;
        if (av != null && bv == null)
            return 1 * ret;
        if (av == null && bv == null)
            return 0;

        if (typeof av === 'string' || av instanceof String)
            if (typeof bv === 'string' || bv instanceof String)
                return av.localeCompare(bv) * ret

        if (av < bv) {
            return -1 * ret;
        }
        if (av > bv) {
            return 1 * ret;
        }
        return 0;
    }




    const sil = (rec) => {
        showConfirmMessage(rec.id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
                fetch("removefilename", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(rec)
                }).then(async response => {
                    if (!response.ok) {
                        ajaxFail(response);
                    }
                    else {
                        response.json().then(value => {
                            setListData(value);
                        })
                    }
                }))
    }

    const onSelect1 = (selectedList, selectedItem) => {
        postdata["BasvuruTipi"] = multiselectRef.current.getSelectedItems().join(", ")
    }
    const onRemove1 = (selectedList, removedItem) => {
        postdata["BasvuruTipi"] = multiselectRef.current.getSelectedItems().join(", ")
    }
    const onSelect2 = (selectedList, selectedItem) => {
        postdata["fileType"] = selectedList.join(", ")
    }
    const onRemove2 = (selectedList, removedItem) => {
        postdata["fileType"] = selectedList.join(", ")
    }


    const getAppTypes = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    var opt = [];
                    for (var x of value) {
                        opt.push(x.name)
                    }
                    setTypeOptions(opt);
                })
            }
        });
    }


    const fetchGrupList = () => {

        fetch("filegroupslist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setFileGrupList(value);
                    fetchFileList();
                })
            }
        });
    }


    const fetchFileList = () => {
        fetch("filenamelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }
    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const getFileGrupName = (apprec) => {
        var r = fileGurups.find(x => x.id == apprec.gurupid);
        if (r != null) {
            apprec.gurup = r.name;//for sort
            return r.name;
        }
        return "";
    }



    const filterColumn = (index) => {
        var cl = ["id", "name", "basvuruTipi", "gurup",  "kontrolorDosyasi"];
        $("#myTable tr").filter(function () {

            for (var c = 0; c < cl.length; c++) {
                var keywordElem = document.getElementById(cl[c]);
                var td = $(this).children().eq(c);
                if (keywordElem != null && isEmpty(keywordElem.value) == false) {
                    var keyword = keywordElem.value;
                    var pass = true;
                    if (keyword.startsWith("-")) {
                        pass = false
                        keyword = keyword.substr(1);
                    }
                    if (keyword.length == 0) break;
                    td.attr('style', 'background: #fff8dd !important');
                    var t1 = removeTurkcLow(td.text());
                    var t2 = removeTurkcLow(keyword);
                    if (keyword == "0" && td.html().includes("checkbox") && !td.html().includes("checked"))
                        return $(this).toggle(true);
                    if (keyword == "1" && td.html().includes("checked"))
                        return $(this).toggle(true);
                    if ((t1.indexOf(t2) == -1) == pass)
                        return $(this).toggle(false);
                }
                else
                    td.removeAttr('style');
            }
            return $(this).toggle(true);
        });
    }


    $('#dosyaadi').on('keypress', function (event) {
        var regex = new RegExp("^[a-zA-Z0-9_()#&%!üğşöçı ÇŞÜĞİÖ+-]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    });


    const renderTable = (tabledata) => {
        var mytable =
            <div >


                <div className="table-responsive mainpagescroll" style={{ "height": "82vh" }}>
                    <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                        <thead className="text-center" style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light">
                           
                                <th width="5%" >
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("id")}>Id</button>
                                    <input type="text" className="form-control form-control-sm" id="" placeholder="" name="id" onChange={() => filterColumn()} />
                                </th>
                                <th width="25%" ><button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("name")}>Dosya Adı</button>
                                    <input type="text" className="form-control form-control-sm" id="name" placeholder="" name="name" onChange={() => filterColumn()} />
                                </th>
                                <th width="47%" ><button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("basvuruTipi")}>Başvuru Türü</button>
                                    <input type="text" className="form-control form-control-sm" id="basvuruTipi" placeholder="" name="basvuruTipi" onChange={() => filterColumn()} />
                                </th>
                                <th width="15%" ><button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("gurup")}>Grup</button>
                                    <input type="text" className="form-control form-control-sm" id="gurup" placeholder="" name="gurup" onChange={() => filterColumn()} />
                                </th>
                                {/*<th width="2%"><button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("zorunlu")}>Zorunlu</button>*/}
                                {/*    <input type="text" className="form-control form-control-sm" id="zorunlu" placeholder="" name="zorunlu" onChange={() => filterColumn()} />*/}
                                {/*</th>*/}
                                <th width="2%"><button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("kontrolorDosyasi")}>Kontrolör</button>
                                    <input type="text" className="form-control form-control-sm" id="kontrolorDosyasi" placeholder="" name="kontrolorDosyasi" onChange={() => filterColumn()} />
                                </th>
                                <th width="6%"></th>
                           
                        </thead>
                        <tbody id="myTable">

                            {tabledata.map(apprec =>
                                <tr key={apprec.id} className="m-0 p-0">
                                    <td>{apprec.id}</td>
                                    <td>
                                        {apprec.name}
                                    </td>
                                    <td>{apprec.basvuruTipi}
                                    </td>
                                    <td>
                                        {getFileGrupName(apprec)}
                                    </td>
                                    <td className="text-center">
                                        <input type="checkbox" className="form-check-input" name="kontrolorDosyasi" checked={apprec.kontrolorDosyasi == true} />
                                    </td>

                                    <td className="text-end">
                                        <button onClick={() => window.changeComponent(<FileSettingDetaile rec={apprec} fileNames={tabledata} />)} className="btn btn-link btn-sm text-decoration-none text-muted p-0 m-0">
                                            <span class="material-icons">
                                                edit
                                            </span>
                                        </button>
                                        <button onClick={() => showConfirmMessage("Dosya silinsin mı?","Bu işlem geri döndürülemez","Evet Sil","Hayır İptal Et", ()=>sil(apprec))  } className="btn btn-link btn-sm text-decoration-none text-danger p-0 m-0">
                                            <span class="material-icons"> delete    </span>
                                        </button>

                                    </td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>
            </div>
        return (mytable);
    }


    const Add = () => {
        window.changeComponent(<FileSettingDetaile rec={{}} fileNames={listData} />)
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }


    const filterTable = (event) => {
        const target = event.target;
        const value = target.value;
        $("#myTable tr").filter(function () {
            var keywords = removeTurkcLow(value)
            var rowtext = "";
            $(this).each(function () {
                rowtext += $(this).text();
            });
            if (removeTurkcLow(rowtext).indexOf(keywords) == -1) {
                        return $(this).toggle(false);
            }
            return $(this).toggle(true);
        });
    }

    useEffect(() => {
        fetchGrupList();
        fetchFileList();
        getAppTypes();
        fetchRoles();
    }, [])


    var listTable = renderTable(listData);

    return (
        <div >


            <div className="row m-3">
                <div className="col-12 col-md-3">
                    <h5>Dosyalar</h5>
                </div>
                <div className="col-12 col-md-6">
                    <input className="form-control form-control-sm" id="myInput" type="text" placeholder="Ara" onKeyUp={filterTable} />

                </div>
                <div className="col-0 col-md-3 text-end">
                    <button onClick={Add} className=" btn btn-primary">Yeni Dosya Oluştur </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>
        </div>

    );
}