import React, { useState, useEffect } from 'react';
import Select from "react-select";
import axios from "axios";
import * as UserPermConstants from '../User/UserPermConstants';
import { isEmpty, removeTurkce, removeTurkcLow, showErrorMessage, showInfoMessage, useGlobalState } from '../GlobalState.js';
import { ajaxFail } from '../GlobalState.js';
import InlineFilePanel from './InlineFilePanel';
import $ from 'jquery';
import DWGCompare from './DWGCompare';

export default function FilesPanel(props) {

    const [files, setFiles] = useState([]);
    const [showDeletedFiles, setshowDeletedFiles] = useState(false);

    const [fileTypes, setfileTypes] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    var basvurutipi = props.appData.basvuruTipi;
    var appData = props.appData;
    var eksikzorunlu = "";
    var zorunluimza = [];
    const [role, setRole] = useGlobalState('role');
    const [sablonlistData, setSabolnListData] = useState([]);

    const [showAllFileState, setshowAllFileState] = useState(false);


    useEffect(() => {

        fetchSabolnFileList();
    }, [])






    const renderSablonFileTable = (tabledata) => {
        var mytable =

            <table aria-labelledby="tabelLabel">
                <thead>
                </thead>
                <tbody >
                    {tabledata.map(apprec =>
                        <tr key={apprec.id} >
                            <td ><a href={"./getsablonfile?id=" + apprec.id} download={apprec.fileName} className="text-primary text-decoration-none">{apprec.fileName}</a></td>
                        </tr>
                    )}
                </tbody>
            </table>

        return (mytable);
    }



    const fetchSabolnFileList = () => {
        fetch("sablonfilenamelist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setSabolnListData(value);
                    fetchFiles();
                })
            }
        });
    }

    const isZorunluForThisApp = (zorunlu) => {
        if (isEmpty(zorunlu) == true || zorunlu.includes(appData.basvuruTipi) == false)
            return false;
        return true;
    }

    const eksikdosyatespit = (appData, uploadedfiles) => {
        var eksikdiv = document.getElementById("eksikdosyalar");

        if (eksikdiv == null || fileTypes == null)
            return;

        var i, j;
        for (i = 0; i < fileTypes.length; i++) {
            if (isEmpty(fileTypes[i].BasvuruTipi) == true && props.showAll != true)
                continue;

            if (isEmpty(fileTypes[i].BasvuruTipi) == false && fileTypes[i].BasvuruTipi.includes(appData.basvuruTipi) == false)
                continue;


            for (j = 0; j < uploadedfiles.length; j++) {
                if (fileTypes[i].Id == uploadedfiles[j].typeId)
                    break;
            }
            if (j == uploadedfiles.length && isZorunluForThisApp(fileTypes[i].zorunlu) == true) {
                if (eksikzorunlu.length > 0)
                    eksikzorunlu += " - "
                eksikzorunlu += fileTypes[i].Name;
            }

            if (fileTypes[i].zorunluEimza == true) {
                if (uploadedfiles.some(x => x.typeId == fileTypes[i].Id && isEmpty(x.imzatarih) == false && x.imzatarih.includes("edildi") == false) == false)
                    zorunluimza.push(fileTypes[i].Name)
            }


        }
        if (eksikzorunlu.length > 0) {
            eksikdiv.innerHTML = "Eksik zorunlu dosyalar bulunmaktadır (" + eksikzorunlu + " )"
        }
        else
            eksikdiv.innerHTML = "";


        var eksikeimzadiv = document.getElementById("eksikeimzalidosya");
        if (zorunluimza.length > 0) {
            eksikeimzadiv.innerHTML = "E-İmza atılması gereken dosyalar: " + zorunluimza.join(',')
        }
        else
            eksikeimzadiv.innerHTML = "";
    }


    const getappeimzafiles = (filelist) => {
        fetch("getappeimzafiles", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {

                    for (var i = 0; i < filelist.length; i++) {
                        var r = data.filter(f => f.removed != true).find(f => f.userFileId == filelist[i].id);
                        if (r != null) {
                            filelist[i].imzatarih = r.imzatarih;
                            filelist[i].imzaid = r.id;
                            filelist[i].removed = r.removed;
                            filelist[i].imzaRec = r;
                        }

                    }

                    setUploadedFiles(filelist);


                });
            }
        });
    }

    const fetchFiles = () => {
        fetch("getapplicationfiles", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...appData, temp: "showremoved" })
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    filenamelist(data);
                    getappeimzafiles(data)
                });
            }
        });
    }




    const filenamelist = (files) => {
        fetch("filenamelistwithgrups", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                response.text().then(value => {
                    showErrorMessage(response.statusText)
                })
            }
            else {
                response.json().then(value => {
                    var filetypes = [];
                    value = JSON.parse(value);
                    for (var i = 0; i < value.length; i++) {
                        if (isEmpty(value[i].BasvuruTipi) && props.showAll != true)
                            continue;

                        if (isEmpty(value[i].BasvuruTipi) || value[i].BasvuruTipi.includes(basvurutipi)) {
                            filetypes.push(value[i])
                        }
                    }


                    var typeids = value.map(x => x.Id);
                    var l = files.filter(x => typeids.includes(x.typeId) == false)
                    l.forEach(x => {
                        if (filetypes.some(f => f.Id == x.typeId) == false)
                            filetypes.unshift({ Name: "", Id: x.typeId, typeLess: true })

                    })
                    setfileTypes(filetypes);

                })
            }
        });
    }




    const SendFiles = (selectedFiles) => {
        for (let i = 0; i < selectedFiles.length; i++) {
            var filename = removeTurkce(selectedFiles[i].file.name);
            if (filename.length > 80) {
                showErrorMessage("Dosya İsmi en fazla 80 karakter olabilir.");
                return;
            }
            if (/^[a-zA-Z0-9_\.\- ()]+$/.test(filename) == false) {
                showErrorMessage("Dosya ismi  belirli özel karakterler içermemelidir.", "İçeremez: + * / $ , # [] {} <> \  = : ;  ! | ? % ^");
                return;
            }
            var formData = new FormData();
            formData.append("file", selectedFiles[i].file);
            formData.append("caption", filename);
            formData.append("typeid", selectedFiles[i].TypeID);
            formData.append("appid", props.appData.id);
            document.getElementById("fuploadspinner" + selectedFiles[i].TypeID).hidden = false;

            axios.post(
                "submitappfile",
                formData,
                {
                    headers: {
                        //"Authorization": "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
                        "Content-type": "multipart/form-data",
                    },
                }
            ).then(res => {
                document.getElementById("fuploadspinner" + selectedFiles[i].TypeID).hidden = true;
                fetchFiles();

            })
                .catch(err => {
                    document.getElementById("fuploadspinner" + selectedFiles[i].TypeID).hidden = true;
                    showErrorMessage(err);
                })
        }
    }

    const handleSelectedFile = (event, filetyp) => {
        if (event.target.files[0] != null) {
            if (filetyp.fileSize != null && event.target.files[0].size > filetyp.fileSize * 1024 * 1024) {
                showErrorMessage("dosya boyu en fazla " + filetyp.fileSize + " MB olabilir");
                event.target.value = "";
                return;
            }
            SendFiles([{ file: event.target.files[0], TypeID: filetyp.Id }]);
        }
    }
    const removeFile = (idx) => {
        files.splice(idx, 1);
        setFiles([...files]);
    }




    const getVisibleState = (id) => {
        if (fileTypes == null) return false;
        for (var i = 0; i < fileTypes.length; i++)
            if (fileTypes[i].Id == id) {
                if (fileTypes[i].kontrolorDosyasi != true)
                    return true;
                if (role.indexOf(UserPermConstants.Kontrolor_Dosyasi_Yukleme) > -1) return true;
                if (fileTypes[i].kontrolorDosyaYetki == null || fileTypes[i].kontrolorDosyaYetki.length == 0) return true;
                if (fileTypes[i].kontrolorDosyaYetki.split(',').some(x => role.indexOf("#" + x + "#") > -1)) return true;
                return false;
            }

        return false;
    }


    const showAllFiles = (event) => {
        var c = document.getElementById("showAllFiles");
        if (c != null && c.checked == true) {
            setshowAllFileState(true);
        }
        else
            setshowAllFileState(false);
    }

    const filterTable = (event) => {
        var textbox = document.getElementById("mySearchInput")
        if (textbox == null)
            return;
        var value = removeTurkcLow(textbox.value);
        var keywords = value.split(" ");

        $("#FileTables .searchGroup").filter(function () {
            return $(this).toggle(false);
        });

        $("#FileTables div .searchitem").filter(function () {
            var t1 = removeTurkcLow($(this).text());
            for (let index = 0; index < keywords.length; ++index) {
                if (t1.indexOf(keywords[index]) == -1) {
                    return $(this).toggle(false);
                }
            }
            $(this).closest('.searchGroup').toggle(true);
            $(this).closest('.searchGroup').find(":first").toggle(true);
            

            return $(this).toggle(true);
        });


    }

    var lastgurup = null;


    const generaterow = (x, hidderState) => {



        var r = getVisibleState(x.Id) && <div className={(lastgurup == x.grupname ? "pt-1 pb-1 searchGroup row" : "row mt-3  border-top border-2 searchGroup")} >
            <div className="text-center mb-1 searchitem" >
                {lastgurup == x.grupname ? "" : <h5 className=" text-primary fw-bold d-inline">{x.grupname}</h5>}

            </div>

            {isEmpty(x.aciklama) == false &&
                <div className="d-flex flex-row searchitem">

                    <span className="badge bg-secondary mb-1 searchitem" title={x.aciklama}>
                        <a class="text-decoration-none text-white " data-bs-toggle="collapse" href={"#collapse" + x.Id} role="button" aria-expanded="false" aria-controls={"#collapse" + x.Id}>
                            Açıklama
                        </a>
                    </span>
                </div>}

            {isEmpty(x.aciklama) == false && <div className="collapse " id={"collapse" + x.Id}>
                <div class="card card-body p-1 mb-1">
                    {x.aciklama}
                </div>
            </div>}

            <div id={"fuploadspinner" + x.Id} hidden={true}>
                <div className="spinner-border spinner-border-sm text-primary" ></div>
            </div>

            <div className="searchitem">
                {<div  style={{ "float": "left" }}>
                    {x.typeLess != true && <button className="btn btn-sm fw-bold rounded-2 p-1 ps-2 pe-2 pt-2 me-2 border-0 shadow-none"
                        disabled={(isEmpty(appData.temp) == false && appData.temp.includes("Görüntüleme") == true) || appData.archived == true}
                        style={{
                            "background-color": x.kontrolorDosyasi == true && role.indexOf(UserPermConstants.Kontrolor_Dosyasi_Yukleme) == -1 ? "#E3E3E3" : "#E1F0FF",
                            "color": x.kontrolorDosyasi == true && role.indexOf(UserPermConstants.Kontrolor_Dosyasi_Yukleme) == -1 ? "#808080" : "#36A4FF"
                        }}
                        onClick={() => {
                            if (x.kontrolorDosyasi == true && role.indexOf(UserPermConstants.Kontrolor_Dosyasi_Yukleme) == -1)
                                showInfoMessage("Bu dosya sadece yetkili belediye personelleri tarafından yüklenebilir.","","Tamam");
                                else
                            document.getElementById("selectedFile" + x.Name).click();
                        }} >
                        <i className="fa fa-plus" style={{ "font-size": "18px" }}></i>
                    </button>}
                    {
                        <div 
                            className={
                                uploadedFiles.filter(r => (showAllFileState == true || r.appId == appData.id) && r.hidden != true
                                && r.typeId == x.Id && (r.removed != true || showDeletedFiles == true)).length == 0 ?
                                    (eksikzorunlu.includes(x.Name) ? "d-inline text-danger fw-bold" : zorunluimza.includes(x.Name) ? "d-inline text-primary fw-bold" : "d-inline fw-bold")
                            :"d-none"}>
                        {x.Name + (isZorunluForThisApp(x.zorunlu) == true ? " *" : "")}
                        {isZorunluForThisApp(x.zorunlu) ? <sup > <span class="badge rounded-pill bg-danger">Zorunlu</span></sup> : ""}
                        {zorunluimza.includes(x.Name) ? <sup> <span class="badge rounded-pill bg-primary">E-İmzalı</span></sup> : ""}
                    </div>}
                </div>}

               


                <div className="" >
                    <InlineFilePanel showAllFileState={showAllFileState}  fileType={x.Id} showDeletedFiles={showDeletedFiles} changeComponent={props.changeComponent} appData={appData} fileNameList={fileTypes} uploadedFiles={uploadedFiles} key={uploadedFiles.length} setParentFileList={setUploadedFiles} setActivePanel={props.setActivePanel} />

                </div>
            </div>
            <div className="row">

                <div className="col-12" >
                    <input type="file" id={"selectedFile" + x.Name} style={{ "display": "none" }} accept={x.FileType != null && x.FileType.length > 0 ? x.FileType : ""} onChange={(e) => handleSelectedFile(e, x)} />

                    {/*<input style={{ maxWidth: "100px", "color": "transparent", "background-color":"#11F0FF"}} class="border-0 form-control form-control-sm" type="file" id="formFile"  />*/}
                </div>
            </div>
        </div>
        lastgurup = x.grupname;

        return r;
    }


    eksikdosyatespit(appData, uploadedFiles);

    return (
        <div>
            <div className="row mb-1">
                <div className="col-12 text-danger fw-bold">
                    {appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Duzeltme_Bekleniyor &&
                        "Başvuru, Düzeltme Bekleniyor aşamasındadır. Düzeltmeler tamamlandıktan sonra “GÖNDER” tuşuna basılması gerekmektedir. "
                    }
                </div>
            </div>


            <div className="row">
                <div className="col-6">
                    {sablonlistData.length > 0 && <h5 className="text-primary">Örnek Formlar</h5 >}
                </div>
                <div className="col-6 align-items-end justify-content-end text-end">
                    <div class="btn-group flex-wrap">
                        {uploadedFiles.filter(x => x.removed == true).length>0 &&<div class="form-check form-switch  text-danger ">
                            Silinen <span className="fw-bold">{uploadedFiles.filter(x => x.removed == true).length}</span> dosyayı Göster<input class="form-check-input" type="checkbox" defaultChecked={false} onClick={(e) => setshowDeletedFiles(e.target.checked)} />
                        </div>}
                    </div>
                </div>
            </div>
            {sablonlistData.length > 0 && <div className="mb-3"><div className="row">
                <div className="col-12">
                    {renderSablonFileTable(sablonlistData)}
                </div>
            </div>
            </div>}
            <div className="row">
                <div className="col-3">
                    <h5 className="text-primary">Dosyalar</h5 >
                </div>
                <div className="col-9 text-danger justify-content-end align-items-end text-end  pe-4" id="eksikdosyalar">
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                </div>
                <div className="col-9 text-primary justify-content-end align-items-end text-end  pe-4" id="eksikeimzalidosya">
                </div>
            </div>


            {fileTypes == null ? <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>
                :
                <fieldset disabled={role.indexOf(UserPermConstants.Dosya_Yukleme) == -1} className="col-12 col-md-12" id="filetable">
                    <div className="pb-4">
                        (*) ile işaretlenen dosyaların yuklenmesi zorunludur.
                    </div>

                    <div >
                        <div className="row border-top pt-3" >

                            <div className="col-12 col-md-2 pe-0" >
                                <div class="form-check form-switch">
                                    <input className="form-check-input pe-0 me-0" type="checkbox" role="switch" id="showAllFiles" onClick={showAllFiles} />
                                       Tüm Dosyalar
                                </div>
                            </div >
                            <div className="col-12 col-md-2" >

                                <div class="">
                                    <button type="button" className="btn btn-primary  btn-sm " onClick={() => {
                                        props.setActivePanel(<DWGCompare setActivePanel={props.setActivePanel} appData={appData} changeComponent={props.changeComponent} files={uploadedFiles.filter(x => x.caption.endsWith(".dwg"))} />)
                                    }}>
                                        DWG <i className="fa fa-copy"></i>
                                    </button>
                                </div>
                            </div >
                            <div className="col-12 col-md-4" >
                                <input className="form-control form-control-sm border-primary border-1 " style={{ "border-width": "1px", "box-shadow": "0px 0px 2px #0099da" }} id="mySearchInput" type="text" placeholder="Arama" onKeyUp={filterTable} />
                            </div >
                            <div className="col-12 col-md-4 textvmuted  text-primary small" >
                                Aralarında boşluk bırakarak birden fazla konuda arama yapabilirsiniz
                            </div  >
                        </div>
                        <div className="row border-bottom pb-2 mb-2" >
                            <div className="col-12 col-md-1" >

                            </div  >
                            <div className="col-12 col-md-10  mt-1" >

                            </div  >

                        </div>

                        <div className=" p-1 mb-2" id="FileTables">
                            {fileTypes.map(x => generaterow(x))}
                        </div >

                        {uploadedFiles.some(x => x.hidden == true) && < div className=" p-1 mt-5 bg-light" >
                            <div className="row mb-2">
                                <div className="col-12 text-center rounded-3"><a href="#" className="text-decoration-none text-secondary fw-bold" data-bs-toggle="collapse" data-bs-target="#silinenliste"> Gizlenen Dosyalar </a></div>
                            </div>
                            <div id="silinenliste" class="collapse">
                                <InlineFilePanel showHidden={true} showAllFileState={showAllFileState} changeComponent={props.changeComponent} appData={appData} fileNameList={fileTypes} uploadedFiles={uploadedFiles} key={uploadedFiles.length} setParentFileList={setUploadedFiles} setActivePanel={props.setActivePanel} />
                            </div >
                        </div >}

                    </div >



                </fieldset>}

        </div >



    );


}
