import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, removeTurkcLow, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';

export default function HavaleSablonSetting(props) {
    var basvuruTipi = props.basvuruTipi;


    const [listData, setListData] = useState([]);
    const [denetimList, setDenetimList] = useState([]);
    const [kontrolorList, setKontrolorList] = useState([]);
    const [postdata, setPostData] = useState({});
    const multiselectRef = React.createRef();
    const multiselectRefNew = React.createRef();
    const colorset = ["#f0f7ff", "#FFFFFF", "#FFFFFF", "#f0f7ff"];

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }


    const fetchDenetimList = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    if (props.isAdimlari)
                        setDenetimList(value.filter(x => x.isadimi == true && props.isAdimlari.some(y=>y.isAdimi==x.id)));
                    else
                        setDenetimList(value.filter(x=>x.isadimi==true));
                })
            }
        });
    }




    const fetchKontrollers = (e) => {
        var rec = e.target.value;
        if (rec == null || rec == -1) {
            if (kontrolorList.length > 0)
                setKontrolorList([])
            return;
        }
        fetch("userswithperm", {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setKontrolorList(data)
                });
            }
        });

    }

    const removeRecord = (rec) => {
        showConfirmMessage(rec.sablonName + " İsimli Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removehavalesablon", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rec)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));
    }




    const fetchList = () => {

        fetch("havalesablonlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    if (basvuruTipi != null)
                        value = value.filter(x => x.basvuruTipi.includes(basvuruTipi.name))

                    setListData(value);
                })
            }
        });
    }

    const Reset = () => {

        document.getElementById("denetim").value = null;
        document.getElementById("kontolor").value = null
        document.getElementById("mapping").value = null
        document.getElementById("parallel").disabled = true;

    }

    const Add = () => {

        var d = document.getElementById("denetim").value
        var k = document.getElementById("kontolor").value
        var parallel = document.getElementById("parallel").checked

        if (d == null || k == null) return;
        if (document.getElementById("mapping").value != null && document.getElementById("mapping").value != "")
            document.getElementById("mapping").value += "," + (parallel == true ? "&" : "");
        document.getElementById("mapping").value += k + "=" + d
        document.getElementById("parallel").disabled = false;

    }

    const SaveSablon = () => {
        var update = true;

        if (postdata.id == null) {
            update=false
        }
        var m = document.getElementById("mapping").value
        var s = document.getElementById("sablonName").value
        
        if (m == null || m == "" || s == null || s == "" ) {
            showErrorMessage("Tüm alanları doldurnuz");
            return;
        }
      //  postdata.basvuruTipi=b
        postdata.mapping = m;
        postdata.sablonName = s;

        if (basvuruTipi != null && isEmpty(postdata.basvuruTipi))
            postdata.basvuruTipi = basvuruTipi.name;
       

        fetch("savehavalesablon", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    if (update)
                        showSuccessMessage("Guncelleme Başarılı")
                    else
                        showSuccessMessage("Şablon Oluşturuldu")

                    if (basvuruTipi != null)
                        value = value.filter(x => x.basvuruTipi.includes(basvuruTipi.name))
                    setListData(value);
                })

            }
        });
    }



    const filterTable = (event) => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function (idx) {
            var keywords = removeTurkcLow(value);
            var childs = $(this).children();
            var text = removeTurkcLow(childs.eq(1).children().eq(0).val()) + " " + removeTurkcLow(childs.eq(3).children().eq(0).val());
            if (listData[idx].basvuruTipi != null)
                text = text + " " + removeTurkcLow(listData[idx].basvuruTipi);
            if (text.toLowerCase().indexOf(keywords.toLowerCase()) == -1)
                return $(this).toggle(false);
            return $(this).toggle(true);
        });

    }

    const renderTable = (tabledata) => {
        var mytable =
            <div className="row ms-2 me-2 pb-3">
                {tabledata.map((apprec, idx) =>
                    <div className={"col-md-6 col-12  "}>
                        <div className="border rounded-3  mt-3 p-0 text-center border-1" style={{ "background-color": colorset[idx % colorset.length] }}>
                            <div className="d-inline float-start">
                                <button onClick={() => removeRecord(apprec)} className="btn btn-secondary btn-sm  text-decoration-none ">Sil</button>
                            </div>
                            <div className="d-inline ">
                                <button onClick={() => setPostData(apprec)} className="btn btn-link btn-sm text-decoration-none ">{apprec.sablonName}</button>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        return (mytable);
    }

    const renderTable2 = (tabledata) => {
        var mytable =
            <div className="table-responsive mainpagescroll" style={{ "height": "70vh" }}>
                <table className='table table-striped  table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light">
                        <tr>
                            <th width="2%">No</th>
                            <th width="15%">İsim</th>
                            <th width="43%">Başvuru Tipi</th>
                            <th width="30%">Havale</th>
                            <th width="10%">
                                <input className="form-control form-control-sm" id="myInput" type="text" placeholder="Arama" onKeyUp={filterTable} />

                            </th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {tabledata.map(apprec =>
                            <tr key={apprec.id}>
                                <td>{apprec.id}</td>
                                <td>

                                    <input type="text" className="form-control form-control-sm bg-white" placeholder="Şablon İsmi" name="sablonName" defaultValue={apprec.sablonName} onChange={(e) => updateRecord(apprec, e)} />

                                </td>
                                <td>

                                </td>
                                <td>
                                    <input type="text" className="form-control form-control-sm bg-white" placeholder="Şablon İsmi" defaultValue={apprec.mapping} name="mapping" onChange={(e) => updateRecord(apprec, e)} />

                                </td>

                                <td>
                                    <button onClick={() => SaveSablon(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                                    <button onClick={() => removeRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                                </td>
                            </tr>
                        )}

                    </tbody>
                </table>
            </div>
        return (mytable);
    }





    useEffect(() => {
        fetchDenetimList();
        fetchList();

    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    /* return (
         <div className="  rounded ">
 
             <h5 className="ps-1">Havale Şablonları</h5>
             <div className="border p-2 rounded-3">
                 <div className="row mt-1">
                     <div className="col-12 col-md-4  p-1">
                         <input type="text" className="form-control  bg-white" id="sablonName" placeholder="Şablon İsmi" name="sablonName" />
                     </div>
                     <div className="col-12 col-md-7  p-1">
 
                         <Multiselect className="p-0 form-control-sm bg-white"
                             options={denetimList}
                             emptyRecordMsg="Başka Kayıt Yok"
                             displayValue="name"
                             isObject={true}
                             ref={multiselectRefNew}
                             placeholder="Başvuru Tipleri"
                          />
 
                     </div>
                 </div>
 
                 <div className="row mt-2">
                     <div className="col-12 col-md-4 p-1">
                         <input type="text" disabled={true} readOnly={true} className="form-control form-control-sm" id="mapping" placeholder="" name="mapping" />
                     </div>
                     <div className="col-12 col-md-3 p-1">
 
                         <select className="form-select form-select-sm " id="denetim" name="denetim" placeholder="Denetim" onChange={fetchKontrollers}>
                             <option value={-1}></option>
                             {denetimList.map(x => <option value={x.id}>{x.name} </option>)}
                         </select>
                     </div>
 
                     <div className="col-12 col-md-3 p-1">
                         <select className="form-select form-select-sm" id="kontolor" name="kontolor" placeholder="Denetim">
                             <option ></option>
                             {kontrolorList.map(x => <option value={x.userName}>{x.name + " " + x.family} </option>)}
                         </select>
                     </div>
                     <div className="col-6 col-md-1 p-1">
                         <input type="checkbox" className="form-check-input" id="parallel" name="parallel" disabled={document.getElementById("mapping") != null && document.getElementById("mapping").value.length == 0} /> Paralel
 
                     </div>
                     <div className="col-6 col-md-1 p-1">
                         <button onClick={Add} className="btn btn-sm btn-primary text-decoration-none ">Ekle</button>
                     </div>
 
                 </div>
 
                 <div className="row mt-2 mb-1">
                     <div className="col-12">
                         <button onClick={() => SaveSablon(null)} className="btn btn-sm btn-primary text-decoration-none ">Şablonu Oluştur</button>
                         <button onClick={Reset} className="btn btn-sm btn-primary text-decoration-none ms-1">Sıfırla</button>
 
                     </div>
 
                 </div>
             </div>
 
 
             <div className="row mt-2">
                 <div className="col-12">
                     {listTable}
                 </div>
             </div>
         </div >
 
     );*/


    return (
        <div className="p-2 rounded-3" style={{ "background-color": "#EDEDF7", "min-height": "100vh" }}>
            <div className="row mt-1 p-3 d-flex flex-wrap pe-4" key={postdata.sablonName}>
                <div className="col-12 col-md-6 d-flex flex-column">
                    <div className=" bg-white rounded-3" >
                        <div className="p-3 fw-bold border-bottom mb-3" >
                            Yeni Havale Şablonu Oluştur
                        </div>
                        <div className="bg-light m-4  p-3 rounded-3 textvmuted fw-bold align-middle"  >
                            <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                            Bu alanda, Yeni Havale Şablonları oluşturabilirsiniz.
                        </div>


                        {isEmpty(postdata.sablonName) != true && < div className="row ms-2 me-2   mb-4 pb-4 border-bottom">
                            <div className="col-12 align-middle   text-center " >
                                <span className="text-success fw-bold"> {postdata.sablonName} </span> şablonunu düzenliyorsunuz (Id: {postdata.id})
                                <button onClick={() => {
                                    setPostData({})
                                }} className="ms-2 btn btn-sm btn-secondary text-decoration-none">Vazgeç</button>
                            </div>
                        </div>}


                        <div className="m-4   pt-1">
                            Şablon Adı <span className="text-danger">*</span>
                            <input type="text" className="form-control form-control-sm " id="sablonName" placeholder="" name="sablonName" defaultValue={postdata.sablonName} />
                            <span className="textvmuted small">Oluşturacağınız şablona bir isim veriniz</span>
                        </div>

                        <div className="m-4  ">
                            Başvuru Tipi <span className="text-danger">*</span>
                            {basvuruTipi == null ?
                               <> <Multiselect className="p-0 ps-1 form-control-sm bg-white"
                                    options={denetimList.map(x => x.name)}
                                    emptyRecordMsg="Başka Kayıt Yok"
                                    displayValue="name"
                                    isObject={false}
                                    ref={multiselectRefNew}
                                    selectedValues={isEmpty(postdata.basvuruTipi) == false ? postdata.basvuruTipi.split(',') : []}
                                    onSelect={(selectedList, removedItem) => { postdata.basvuruTipi = selectedList.length == 0 ? null : selectedList.join() }} // Function will trigger on select event
                                    onRemove={(selectedList, removedItem) => { postdata.basvuruTipi = selectedList.length == 0 ? null : selectedList.join() }} // Function will trigger on remove event
                                /> <div className="textvmuted small">Şablonun hangi başvuru tiplerinde görüntüleneceğini seçin</div></>
                                : <div>{postdata.basvuruTipi}</div>
                            }
                           
                        </div>

                         <div className="row ms-3 mt-1">
                            <div className="col-12 col-md-4 pe-0">
                                İş Adımları <span className="text-danger">*</span>
                                <select className="form-select form-select-sm " id="denetim" name="denetim" placeholder="Denetim" onChange={fetchKontrollers}>
                                    <option value={-1}></option>
                                    {denetimList.map(x => <option value={x.id}>{x.name} </option>)}
                                </select>
                            </div>

                            <div className="col-12 col-md-4 pe-0">
                                Kontrolörler <span className="text-danger">*</span>
                                <select className="form-select form-select-sm" id="kontolor" name="kontolor" placeholder="Denetim">
                                    <option ></option>
                                    {kontrolorList.map(x => <option value={x.userName}>{x.name + " " + x.family} </option>)}
                                </select>
                            </div>
                            <div className="col-6 col-md-2">
                                <br />
                                <input type="checkbox" className="form-check-input" id="parallel" name="parallel" disabled={document.getElementById("mapping") != null && document.getElementById("mapping").value.length == 0} /> Paralel

                            </div>
                            <div className="col-6 col-md-2">
                            <br/>
                                <button onClick={Add} className="btn btn-sm btn-primary text-decoration-none ">Ekle</button>
                            </div>
                        </div>

                        <div className="m-4 mt-3">
                            
                            <input disabled={true} readOnly={true} type="text" id="mapping" name="mapping" className="form-control form-control-sm bg-white"  value={postdata.mapping} name="mapping" />
                            <span className="textvmuted small">Kaydedilecek olan havale şablonu</span>
                        </div>

                        <div className="row mt-2 mb-2">
                            <div className="col-12 text-center">
                                <button onClick={SaveSablon} className="btn btn-sm btn-primary text-decoration-none ">Şablonu Kaydet</button>
                                <button onClick={Reset} className="btn btn-sm btn-primary text-decoration-none ms-1">Sıfırla</button>
                            </div>
                        </div>

                        {/*        <div className="m-4 mb-3">*/}
                        {/*            Ekran Tipi <span className="text-danger">*</span>*/}
                        {/*            <div className="row" >*/}
                        {/*                <div className="col-md-2 col-12">*/}
                        {/*                    <div class="form-check form-check-inline">*/}
                        {/*                        <input class="form-check-input big-checkbox me-1" type="checkbox" value="" id="forListView" name="forListView" defaultChecked={postdata.forListView} />*/}
                        {/*                        <label class="form-check-label pt-1" for="flexCheckDefault">*/}
                        {/*                            List*/}
                        {/*                        </label>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className="col-md-2 col-12">*/}
                        {/*                    <div class="form-check form-check-inline">*/}
                        {/*                        <input class="form-check-input big-checkbox me-1" type="checkbox" value="" id="forTableView" name="forTableView" defaultChecked={postdata.forTableView} />*/}
                        {/*                        <label class="form-check-label pt-1" for="flexCheckChecked">*/}
                        {/*                            Kart*/}
                        {/*                        </label>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className="col-md-8 col-12">*/}
                        {/*                    <span className="textvmuted small">Oluşturduğunuz ekranın Liste Görünümü ve Kart Görünümünden hangilerinde yer alacağını seçin</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}


                        {/*        <div className="row mt-5 m-3 mt-1 border-top ">*/}
                        {/*            <div className="col-md-11 col-12">*/}
                        {/*                Uygulanacak Filtre*/}
                        {/*                <input type="text" className="form-control form-control-sm" id="filter" placeholder="Filtre" name="filter" defaultValue={postdata.filter} />*/}

                        {/*            </div>*/}
                        {/*            <div className="col-md-1 col-12 text-end">*/}
                        {/*                <br />*/}
                        {/*                <button onClick={ClearFilter} className="ms-1 btn btn-sm btn-secondary">Sil</button>*/}

                        {/*            </div>*/}

                        {/*        </div>*/}



                        {/*        <div className="row m-3 mt-1">*/}
                        {/*            <div className="col-md-4 col-12 border-top mt-4">*/}
                        {/*            </div>*/}
                        {/*            <div className="col-md-4 col-12">*/}
                        {/*                Filtre Ekleme Yöntemi*/}
                        {/*                <select className="form-select form-select-sm " id="andor" name="andor" placeholder="Filtre" disabled={isEmpty(postdata.filter)}>*/}
                        {/*                    <option value="AND"> AND </option>*/}
                        {/*                    <option value="OR"> OR </option>*/}
                        {/*                </select>*/}

                        {/*            </div>*/}
                        {/*            <div className="col-md-4 col-12 border-top mt-4">*/}
                        {/*            </div>*/}

                        {/*        </div>*/}



                        {/*        <div className="m-3">*/}
                        {/*            <div className="row">*/}
                        {/*                <div className="col-md-4 col-12">*/}
                        {/*                    Filtrelenecek Alan*/}
                        {/*                    <select className="form-select form-select-sm " id="filterColSelect" name="filterColSelect">*/}
                        {/*                        {options.map(rec => <option value={rec}> {rec} </option>)}*/}
                        {/*                    </select>*/}
                        {/*                </div>*/}
                        {/*                <div className="col-md-2 col-12 ps-0">*/}
                        {/*                    Operator*/}
                        {/*                    <select className="form-select form-select-sm " id="oper" name="oper" placeholder="Filtre">*/}
                        {/*                        <option value="=">{"="} </option>*/}
                        {/*                        <option value="!="> {"!="} </option>*/}
                        {/*                        <option value=">"> {">"} </option>*/}
                        {/*                        <option value="<"> {"<"} </option>*/}
                        {/*                        <option value="LIKE"> {"LIKE"} </option>*/}
                        {/*                        <option value="NOTLIKE"> {"NOTLIKE"} </option>*/}
                        {/*                        <option value="ISNULL"> {"ISNULL"} </option>*/}
                        {/*                        <option value="ISNOTNULL"> {"ISNOTNULL"} </option>*/}

                        {/*                    </select>*/}
                        {/*                </div>*/}
                        {/*                <div className="col-md-4 col-12 ps-0">*/}
                        {/*                    Filtre Değeri*/}
                        {/*                    <input type="text" className="form-control form-control-sm" id="filtervalue" placeholder="Filtre Değeri" name="filter" pattern="[a-zA-Z0-9ÜüŞşÇçÖöĞğİı ]+" />*/}

                        {/*                </div>*/}
                        {/*                <div className="col-md-2 p-0 col-12 "><br />*/}
                        {/*                    <button onClick={AddFilter} className="btn btn-sm btn-primary">Ekle</button>*/}

                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}



                        {/*        <div className="m-3 mt-5">*/}

                        {/*            <div className="row ">*/}
                        {/*                <div className="col-md-6 col-12">*/}
                        {/*                    Sıralama Uygulanacak  Alan*/}
                        {/*                    <select className="form-select form-select-sm" id="siraColSelect" name="siraColSelect" >*/}
                        {/*                        {isEmpty(postdata.colNames) == false && postdata.colNames.split(",").map(x => <option value={x} >{x}</option>)}*/}
                        {/*                    </select>*/}
                        {/*                </div>*/}
                        {/*                <div className="col-md-6 col-12 text-end"><br />*/}
                        {/*                    <button onClick={AddArtan} className="btn btn-sm btn-primary">Artan</button>*/}
                        {/*                    <button onClick={AddAzalan} className="ms-1 btn btn-sm btn-info">Azalan</button>*/}
                        {/*                    <button onClick={clearSort} className="ms-1 btn btn-sm btn-secondary">Sil</button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className=" mt-1">*/}
                        {/*                Uygulanacak Sıralama*/}
                        {/*                <input type="text" className="form-control form-control-sm" id="sort" placeholder="Sıralama" name="sort" disabled defaultValue={postdata.sort} />*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="row mt-3">*/}
                        {/*            <div className="col-12 text-center pb-2">*/}
                        {/*                <button onClick={Add} className="btn  btn-primary m-1">Ekranı Kaydet</button>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                    </div>
                </div>
                {/*----------------------------------------------------------------------------------------------------*/}
                <div className="col-12 col-md-6 d-flex flex-column bg-white rounded-3">
                    <div className=" " >
                        <div className="p-3 fw-bold border-bottom" >
                            Oluşturulan Şablonlar
                        </div>
                        <div className="bg-light m-3  p-3 mb-2 rounded-3 textvmuted fw-bold align-middle"  >
                            <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                            Oluşturduğunuz havale şablonları silebilir veya özelliklerini değiştirebilirsiniz. Şablon isminin üzerine tıkladıktan sonra “Yeni Şablon Oluşturma” alanından özelliklerini düzenleyebilirsiniz.                       </div>
                        <div className="">
                            {listData != null ? renderTable(listData) : ""}
                        </div>

                    </div>
                </div>
                {/*----------------------------------------------------------------------------------------------------*/}
            </div >
        </div >



    );







}