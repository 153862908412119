import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, sendpost, isEmpty, showConfirmMessage, showSuccessMessage } from '../GlobalState.js';
import { typeOf } from 'react-is';
import BasvuruTipleriDetail from './BasvuruTipleriDetail.js';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

export default function BasvuruTipleriList(props) {
    var postdata = {};
    const [listData, setListData] = useState([]);
    const [appTypeGroups, setappTypeGroups] = useState([]);
    const [basvuruTipiAdimlari, setBasvuruTipiAdimlari] = useState([]);
    const multiselectRef = React.createRef();

    const multiselectRefNew = React.createRef();
    
    const [showIsAdimlari, setShowIsAdimlari] = useState(false);

    useEffect(() => {
        fetchDenetimList();
        fetchAppTypeGrupList();
        getbasvurutipiadiımlari();

        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        })

    }, [])



    const fetchAppTypeGrupList = () => {
        fetch("apptypegroupslist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setappTypeGroups(value);

                })
            }
        });
    }


    const fetchDenetimList = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }


    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trim();
        const name = target.name;
        rec[name] = value;
        if ((name == "deadline" || name == "precondition") && value == '')
            rec[name] = null;
    }

    const renderIsAdimiTable = (tabledata) => {
        if (tabledata == null)
            return "";
        var mytable =
            <div className="table-responsive mainpagescroll mt-2" style={{ "height": "85vh" }} >
                <table className='table table-vdstriped  table-sm ' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light ">

                        <th className="text-start">Id</th>
                        <th className="text-start">
                            Yapılan İşin Adı
                            <button type="button" className="btn btn-secondary bg-secondary btn-sm fw-bold pt-0 pb-0 mt-0 ms-2" id="mypopover2" data-bs-toggle="popover" data-bs-trigger="focus" title="AÇIKLAMA" data-bs-content="'Başvuru Tipleri' tab'ında yer alan başvurulara ilişkin yapılan havaleler ile ilgilidir. Kontrolörlerin ilgili başvuruda gerçekleştirdikleri eylemlere verilen isimleridir. Bu sayede o başvuruda hangi kontrolör tarafından hangi işlerin yapıldığı görülmüş olur.">?</button>

                        </th>
                        <th className="text-start">Aktif</th>
                        <th className="text-start">Kontrol Süre</th>
                        <th className="text-start">Düzeltme Süre</th>
                        <th className="text-start">Düzeltme Sayı</th>
                        <th className="text-end"></th>
                    </thead>
                    <tbody id="myTable">
                        {tabledata.map(apprec =>
                            <tr key={apprec.id}>

                                <td>{apprec.id}</td>
                                <td>
                                    <input type="text" className="form-control form-control-sm  " style={{ "background-color": "transparent", "min-width": "200px" }} placeholder="Denetim Adı" name="Name" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.name} />
                                </td>
                                <td>
                                    <input type="checkbox" className="form-check-input" name="active" defaultChecked={apprec.active} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td style={{ "min-width": "60px" }}><input type="number" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} placeholder="" name="deadline" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.deadline} /></td>
                                <td style={{ "min-width": "60px" }}><input type="number" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} placeholder="" name="duzeltmeDeadline" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.duzeltmeDeadline} /></td>
                                <td style={{ "min-width": "60px" }}><input type="number" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} placeholder="" name="duzeltmeSayi" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.duzeltmeSayi} /></td>


                                <td className="text-end">  
                                    <button onClick={() => { savedenetim(apprec); }} className="btn btn-link btn-sm text-decoration-none m-0 p-0"><i className="fa fa-floppy-o fs-6" aria-hidden="true" title="Güncelle"></i></button>
                                    &nbsp;&nbsp;
                                    {/*  <button onClick={() => {
                                        showConfirmMessage(apprec.hide == true ? "Kayıt Başvuru Tiplerine Taşınacaktır" : "Kayıt İş Adımlarına Taşınacaktır", "", "Devam Et", "İptal", () => {
                                            apprec.hide = apprec.hide == true ? false : true;
                                            savedenetim(apprec);
                                        })


                                    }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">
                                        <i className={apprec.hide == true ? "fa fa-book  fs-6" : "fa fa-code-fork fs-6"} aria-hidden="true" title={apprec.hide == true ? "Başvuru Tiplerine Taşı" : "İş Adımlarına Taşı"}></i></button>*/}

                                    <button onClick={() => { sil(apprec); }} className="btn btn-link btn-sm text-decoration-none m-0 p-0"><i className="fa fa-trash text-danger fs-6" aria-hidden="true" title="Sil"></i></button>

                                </td>
                            </tr>
                        )}

                        <tr className="bg-white">
                            <td className="text-success">
                            Yeni
                             </td>
                            <td>
                                <input type="text" className="form-control form-control-sm" id="DenetimAdi" placeholder="Denetim Adı" name="Name" onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="checkbox" className="form-check-input" name="active" id="active" defaultChecked={false} onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm" id="deadline" placeholder="" name="deadline" onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm" id="duzeltmeDeadline" placeholder="" name="duzeltmeDeadline" onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm" id="duzeltmeSayi" placeholder="" name="duzeltmeSayi" onChange={handleInputChange} />
                            </td>

                            <td className="text-end">  <button onClick={() => savedenetim(null)} className="btn btn-sm btn-link text-decoration-none m-0 p-0"><i class="fa fa-plus-circle fs-5" aria-hidden="true" title="Ekle"></i></button>

                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        return (mytable);
    }


    const renderTable = (tabledata) => {
        if (tabledata == null)
            return "";
        var mytable = <div>
            <div className=" text-end mt-1">
                <button onClick={() => window.changeComponent(<BasvuruTipleriDetail basvuruTipi={new Object()} basvuruTipleri={listData} appTypeGroups={appTypeGroups} basvuruTipiAdimlari={[]} />)} className=" btn btn-sm btn-primary">Yeni Başvuru Tipi Oluştur </button>
            </div>
            <div className="table-responsive mainpagescroll mt-1" style={{ "height": "85vh" }} >

                <table className='table table-vdstriped  table-sm ' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light ">

                        <th className="text-start">Id</th>
                        <th className="text-start"> Başvuru Adı</th>
                        <th className="text-start"> Ekleme Tarihi</th>
                        
                        <th className="text-start">Aktif</th>
                        <th className="text-start">Onay</th>
                        <th className="text-start">Ruhsat</th>
                        <th className="text-start">Zorunlu Dosya Kontrol</th>
                        <th className="text-start">Hızlı Arşiv</th>

                        <th className="text-end"></th>
                    </thead>
                    <tbody id="myTable">
                        {tabledata.filter(x => (x.isadimi == true) == showIsAdimlari).map(apprec =>
                            <tr key={apprec.id}>
                                <td>{apprec.id}</td>
                                <td>
                                    {apprec.name}
                                </td>
                                <td>
                                    {apprec.crerateTime}
                                </td>
                                
                                <td>
                                    <input type="checkbox" className="form-check-input" name="active" checked={apprec.active} disabled />
                                </td>
                                {showIsAdimlari == false && <td>
                                    <input type="checkbox" className="form-check-input" name="onaysureci" checked={apprec.onaysureci} disabled />
                                </td>}
                                {showIsAdimlari == false && <td>
                                    <input type="checkbox" className="form-check-input" name="ruhsatsureci" checked={apprec.ruhsatsureci} disabled />
                                </td>}
                                {showIsAdimlari == false && <td>
                                    <input type="checkbox" className="form-check-input" name="eksiksiz" checked={apprec.eksiksiz} disabled />
                                </td>}
                                {showIsAdimlari == false && <td>
                                    <input type="checkbox" className="form-check-input" name="hizliArsiv" checked={apprec.hizliArsiv} disabled />
                                </td>}


                                {/*<td>{apprec.creatorUserName}</td>*/}
                                {/*<td>{apprec.crerateTime}</td>*/}
                                <td className="text-end">  <button onClick={() => {
                                    window.changeComponent(<BasvuruTipleriDetail basvuruTipleri={listData} basvuruTipi={apprec} appTypeGroups={appTypeGroups} basvuruTipiAdimlari={basvuruTipiAdimlari} />)

                                }} className="btn btn-link btn-sm text-decoration-none m-0 p-0"><i className="fa fa-edit fs-6" aria-hidden="true" title="Güncelle"></i></button>
                                    &nbsp;&nbsp;
                                    {/*  <button onClick={() => {
                                        showConfirmMessage(apprec.hide == true ? "Kayıt Başvuru Tiplerine Taşınacaktır" : "Kayıt İş Adımlarına Taşınacaktır", "", "Devam Et", "İptal", () => {
                                            apprec.hide = apprec.hide == true ? false : true;
                                            savedenetim(apprec);
                                        })


                                    }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">
                                        <i className={apprec.hide == true ? "fa fa-book  fs-6" : "fa fa-code-fork fs-6"} aria-hidden="true" title={apprec.hide == true ? "Başvuru Tiplerine Taşı" : "İş Adımlarına Taşı"}></i></button>*/}

                                    <button onClick={() => { sil(apprec); }} className="btn btn-link btn-sm text-decoration-none m-0 p-0"><i className="fa fa-trash text-danger fs-6" aria-hidden="true" title="Sil"></i></button>

                                </td>
                            </tr>
                        )}

                    </tbody>
                </table>
            </div>
        </div>
        return (mytable);
    }

    const sil = (rec) => {
        var r = rec;

        showConfirmMessage(rec.name + " İsimli Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removedenetim", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rec)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));

    }

    const getbasvurutipiadiımlari = (rec) => {
        sendpost("basvurutipisadimlist", null, "", (response) => {
            response.json().then(value => {
                setBasvuruTipiAdimlari(value);
            })
           
        })
    }

    const savedenetim = (rec) => {
        var r = rec;
        if (r == null) {
            postdata.isadimi = showIsAdimlari;
            r = postdata;
        }

        fetch("savedenetim", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(r)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                if (rec != null) {
                    showSuccessMessage("İş Adımı Güncellendi. ")
                    setListData([...listData]);
                }
                else {
                    fetchDenetimList();
                    if (document.getElementById("DenetimAdi")) document.getElementById("DenetimAdi").value = "";
                    if (document.getElementById("active")) document.getElementById("active").checked = false;
                    if (document.getElementById("onaysureci")) document.getElementById("onaysureci").checked = false;
                    if (document.getElementById("ruhsatsureci")) document.getElementById("ruhsatsureci").checked = false;
                    if (document.getElementById("hide")) document.getElementById("hide").checked = false;
                    if (document.getElementById("eksiksiz")) document.getElementById("eksiksiz").checked = false;
                    if (document.getElementById("deadline")) document.getElementById("deadline").value = "";
                    if (document.getElementById("duzeltmeDeadline")) document.getElementById("duzeltmeDeadline").value = "";
                    if (document.getElementById("duzeltmeSayi")) document.getElementById("duzeltmeSayi").value = "";
                    if (document.getElementById("hizliArsiv")) document.getElementById("hizliArsiv").checked = false;
                    if (document.getElementById("grup")) document.getElementById("grup").selectedIndex = -1;
                    if (multiselectRefNew.current) multiselectRefNew.current.resetSelectedValues();
                    postdata = {};

                }
            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (target.type === 'text' && isEmpty(value) == false)
            value = value.trim();

        postdata[target.name] = value;

        if (target.name == "deadline" && value == '')
            postdata[target.name] = null;

    }






    return (
        <div className="">
            <ul className="nav nav-tabs nav-pills">
                <li className="nav-item">
                    <a className="nav-link active" id="basvurTipleri" aria-current="page" href="#" onClick={(e) => {
                        document.getElementById("isAdimlari").classList.remove('active');
                        document.getElementById("basvurTipleri").classList.add('active');
                        setShowIsAdimlari(false);
                    }}>Başvuru Tipleri <i className={"fa fa-book  fs-6"} aria-hidden="true" ></i></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#" id="isAdimlari"
                        onClick={(e) => {
                            document.getElementById("basvurTipleri").classList.remove('active');
                            document.getElementById("isAdimlari").classList.add('active');
                            setShowIsAdimlari(true);
                        }}
                    >İş Adımları <i className={"fa fa-code-fork fs-6"} aria-hidden="true" ></i></a>
                </li>
            </ul>
            <div className="row">
                <div className="col-12" >
                    {showIsAdimlari != true ? renderTable(listData.filter(x => x.isadimi != true)) : renderIsAdimiTable(listData.filter(x => x.isadimi == true))}
                </div>
            </div>
        </div>

    );


}