import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { ajaxFail, isEmpty, useGlobalState, getappdata, showErrorMessage, showSuccessMessage} from '../GlobalState.js';

import NotePanel from './NotePanel.js';
import EventPanel from "./EventPanel.js";
import FilesPanel from "./FilesPanel.js";
import ApplicationAuthorPanel from "./ApplicationAuthorPanel.js";
import Sharing from './Sharing.js';
//import $ from 'jquery';
import ApplicationPrint from "./ApplicationPrint.js";
import * as UserPermConstants from '../User/UserPermConstants';
import AppMainData from "./AppMainData.js";

import HavaleYetkiliciPanel from "./HavaleYetkiliciPanel.js";
import BasvuruKabulPanel from "./BasvuruKabulPanel.js";
import OnayYetkilisiPanel from "./OnayYetkilisiPanel.js";
import DenetimPaneli from "./DenetimPaneli.js";
import UserLabel from "../User/UserLabel.js";
import EImzaList from './EImzaList.js';
import ApplicationList from './ApplicationList.js';
import Home from '../Dashboard/Home.js';
import MaksList from '../MAKS/MaksList.js';


export default function Application(props) {
    const [appData, setData] = useState(props.appData != null ? props.appData : location.state.rec);
    const [onmobile, setOnMobile] = useState(window.innerWidth < 680 ? true : false)
    const changeComponent = props.changeComponent;
    const [backPage, setBackPage] = useState(props.backButton);
    let location = useLocation();
    const [sabitsorulistData, setsabitsorulistData] = useState([]);
    const [userName, setUserName] = useGlobalState('userName');
    const [following, setFollowing] = React.useState();
    const ref = React.createRef();
    const [denetimpaenls, setDenetimPaenls] = useState([]);

    const [apptypeList, setAppTypeList] = React.useState([]);

    const [activePanel, setActivePanel] = useState(null)
      

    const [relatedapps, setRelatedapps] = useState([]);

    const [role, setRole] = useGlobalState('role');
    const [sharingType, setSharingType] = useState('');



    const zorunloBosBirakma = role.indexOf(UserPermConstants.Yeni_Basvuruda_Zorunlu_Alanlari_Bos_Birakabilme) == -1;

    useEffect(() => {
        
        isFollower();
        getRelatedApps();
        getAppTypes();
        window.scrollTo(0, 0)
        if (appData != null && isEmpty(appData.temp) == false && appData.temp.includes("#")) {
            setSharingType(appData.temp.split("#")[1]);
               }
    }, [])


    if (appData != null && relatedapps != null && relatedapps.length > 0) {
        appData.relatedApps = relatedapps;  //we use this to show related apps file in inlinefile component
        
    }


    const getAppTypes = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setAppTypeList(value);
                })
            }
        });
    }

    var sidebarPanel = [];
    if (role.indexOf("kartPaylasimi") > -1)
        sidebarPanel.push({ target: "/appsharing", state: {}, name: "Paylaşım", icon: "fa fa-share-alt" })



    //$(window).resize(function () {

    //    if ($(this).width() < 680 && onmobile == false) {
    //        setOnMobile(true)
    //    }
    //    else if (onmobile == true){
    //        setOnMobile(false)
    //    }
    //});



    const getApp = () => {
        fetch("getapp", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setData(value);
                })
            }
        });
    }



    var operationPanel = [];
    if (role.indexOf(UserPermConstants.Basvuru_Onaylama) >= 0 && appData.archived != true && appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Taslak)
        operationPanel.push({ name: "Başvuru Kabul", panel: < BasvuruKabulPanel rec={appData} relatedApps={relatedapps} changeComponent={props.changeComponent} /> });
    if (role.indexOf(UserPermConstants.Havale_Yapma) >= 0 && appData.onKontrol == true && appData.archived != true)
        operationPanel.push({ name: "Havale", panel: <HavaleYetkiliciPanel rec={appData} relatedapps={relatedapps} changeComponent={props.changeComponent} getApp={getApp} refreshParent={() => { getmypermissionsonproject(); getApp(); }} /> });

    if (role.indexOf(UserPermConstants.Basvuru_Onaylama_Kontrolorden_Sonraki_Biti_Onayi) >= 0 && appData.onKontrol == true && appData.archived != true)
        operationPanel.push({ name: "Onay", panel: <OnayYetkilisiPanel rec={appData} changeComponent={props.changeComponent} /> });
    //if (role.indexOf(UserPermConstants.Ruhsat_Kesme) >= 0 && appData.onKontrol == true)
    //    pan.push({ name: "Ruhsat Kesme", panel: <RuhsatKesmeYetkilisiPanel rec={appData} changeComponent={props.changeComponent} /> });

    operationPanel = operationPanel.map(x => <div className="mt-2">
        <button type="button" className="btn fw-bold  btn-sm text-muted text-decoration-none" onClick={(e) => { makeBold(e); setActivePanel(x.panel) }}>
            {x.name}
        </button>
    </div>

    )


    const isFollower = () => {
        fetch("isfollower", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {
            if (!response.ok) { ajaxFail(response); }
            else { setFollowing(true); }
        });
    }



    const changeFollow = (targeturl) => {
        fetch(targeturl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {
            if (!response.ok) { ajaxFail(response); }
            else { }
        });
    }

    const getRelatedApps = () => {
        fetch("getrelatedapps", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setRelatedapps(value)
                    getmypermissionsonproject();
                })
            }
        });
    }


    const handleFollowingChange = (event) => {
        const target = event.target;
        if (target.checked)
            changeFollow("followapp");
        else
            changeFollow("disfollowapp");

    }



    const getmypermissionsonproject = (callback) => {
        fetch("getmypermissionsonproject", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    if (data != null && data.length > 0) {
                        data = JSON.parse(data);
                        setDenetimPaenls(data);

                    }
                });
            }
        });
    }

    const makeBold = (e) => {
        var btns = document.getElementById("buttonsbox").querySelectorAll(".btn");
        btns.forEach(x => { if (x.classList.contains("btn-link") == false) { x.classList.remove("btn-primary"); x.classList.add("text-muted") } })
        if (e == null) return;
        var b = e.target;
        b.classList.remove("text-muted")
        b.classList.add("btn-primary")
    }

    const zorunlu = (name) => {
        var r = sabitsorulistData.filter(x => x.name.toUpperCase() == name.toUpperCase() && x.basvuruTipi != null && x.basvuruTipi.includes(appData.basvuruTipi));
        if (r.length == 0)
            return "*";

        if (r[0].zorunlu == true)
            return ""
        return "*";
    }




    const saveApp = () => {
        fetch("saveapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async newapp => {
                    if (newapp.updateCount < 0) {
                        showErrorMessage(newapp.temp);
                        getappdata(newapp.id, setData);

                    }
                    else {
                        changeComponent(<ApplicationList changeComponent={changeComponent} />)
                    }

                });
            }
        });
    }

    const handleRelatedAppSelect = (event) => {
        if (event.target.value == -1) return;
        props.changeComponent(<Application key={relatedapps[event.target.value].id} appData={relatedapps[event.target.value]} changeComponent={props.changeComponent}
            backButton={<Application key={appData.id} appData={appData} changeComponent={props.changeComponent} />} />)
    }


    const submitApp = (eimza) => {
        if (document.getElementById("basvuruYapan") == null) {
            setActivePanel(<AppMainData key={appData} appData={appData} denetimlist={denetimpaenls} changeComponent={changeComponent} setsabitsorulistData={setsabitsorulistData} refreshParent={() => { getmypermissionsonproject(); getApp(); }} />)

        }

        appData.eimzagonder = eimza;


        if (isEmpty(appData.basvuruTipi)) {
            showErrorMessage("başvuru tipi boş olamaz"); return;
        }

        if (appData.changed == true) {
            showErrorMessage("Başvuru bilgileri değiştiğinden dolayı göndermeden önce Kaydet butonuna basarak başvuruyu kaydediniz"); return;
        }



        if (isEmpty(appData.basvuruYapan) && zorunlu("basvuruYapan") == "*" && zorunloBosBirakma) {
            showErrorMessage("başvuru yapan boş olamaz"); return;
        }
        if (isEmpty(appData.arsaSahibi) && zorunlu("arsaSahibi") == "*" && zorunloBosBirakma) {
            showErrorMessage("Taşınmaz sahibi boş olamaz"); return;
        }
        if (isEmpty(appData.arsaSahibiTel) && zorunlu("arsaSahibiTel") == "*" && zorunloBosBirakma) {
            showErrorMessage("Taşınmaz sahibi telefonu boş olamaz"); return;
        }
        if (isEmpty(appData.arsaSahibiTC) && zorunlu("arsaSahibiTC") == "*" && zorunloBosBirakma) {
            showErrorMessage("Taşınmaz sahibi T.C boş olamaz"); return;
        }
        //if (isEmpty(appData.mevki) && zorunlu("Mevki") == "*" && zorunloBosBirakma) {
        //    alert("mevki boş olamaz"); return;
        //}
        if (isEmpty(appData.mahalle) && zorunlu("Mahalle") == "*" && zorunloBosBirakma) {
            showErrorMessage("mahalle boş olamaz"); return;
        }
        if (isEmpty(appData.ada) && zorunlu("Ada") == "*" && zorunloBosBirakma) {
            showErrorMessage("ada boş olamaz"); return;
        }
        //if (isEmpty(appData.idariMahalle) && zorunlu("IdariMahalle") == "*" && zorunloBosBirakma) {
        //    alert("idari mahalle boş olamaz"); return;
        //}
        if (isEmpty(appData.sokak) && zorunlu("Sokak") == "*" && zorunloBosBirakma) {
            showErrorMessage("sokak boş olamaz"); return;
        }
        if (isEmpty(appData.no) && zorunlu("No") == "*" && zorunloBosBirakma) {
            showErrorMessage("no boş olamaz"); return;
        }

        if (eimza == true)
            document.getElementById("eimzagonderbuttonSpinner").hidden = false;
        else
            document.getElementById("gonderbuttonSpinner").hidden = false;

        fetch("submitapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData)
        }).then(async response => {
            var eimzab1 = document.getElementById("eimzagonderbuttonSpinner");
            if (eimzab1 != null)
                eimzab1.hidden = true;

            eimzab1 = document.getElementById("gonderbuttonSpinner");
            if (eimzab1 != null)
                eimzab1.hidden = true;

            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async newapp => {
                    if (newapp.updateCount == -1) {
                        showErrorMessage("Başvuru bilgileri başka kullanıcı tarafından değiştiği için başvuru gönderilmedi. Yeni bilgiler sayfaya yuklenecek.");
                        getappdata(newapp.id, setData);
                    }
                    else if (newapp.updateCount == -2) {
                        showErrorMessage("Başvuruya devam edebilmek için kırmızı olarak işaretlenen zorunlu dosyaları yüklemeniz gerekmektedir.", "Zorunlu dosyaları (" + newapp.temp + ") yükledikten sonra başvuruyu gönderebilirsiniz.");
                        newapp.temp = "";
                        getappdata(newapp.id, setData);
                    }
                    else if (newapp.updateCount == -3) {
                        showErrorMessage(newapp.temp);
                        newapp.temp = "";
                        getappdata(newapp.id, setData);
                    }
                    else {
                        setTimeout(function () {
                            showSuccessMessage("Başvurunuz Gönderilmiştir","",()=>changeComponent(<ApplicationList changeComponent={changeComponent} />))
                            
                        }, 10)
                    }
                });





            }
        });
    }

    const getControllorColor = (rec) => {
        if (isEmpty(rec.Completed) == false) return "text-success";
        if (rec.activeDenetim == rec.sira && rec.duzeltmeDurum == true) return "text-warning";
        if (rec.activeDenetim == rec.sira) return "text-primary";
        return "text-muted";
    }
    const getControllorDurumText = (rec) => {
        if (isEmpty(rec.Completed) == false) return <span class="badge rounded-pill bg-success ps-1" title="Kontrol tamamlandı">Kontrol Tamamlandı</span>;
        if (rec.activeDenetim == rec.sira && rec.duzeltmeDurum == true) return <span class="badge rounded-pill bg-warning  ps-1" title="Kontrolor düzeltme istemiş">Düzeltme Bekleniyor</span>;
        if (rec.activeDenetim == rec.sira) return <span class="badge rounded-pill bg-primary  ps-1" title="Kontrol devam ediyor">Kontrol Aşamasında</span>;
        return <span class="badge bg-secondary  ps-1" title="Sıra bekleniyor">Sırada</span>;
    }

    var gonder = "";
    if (role.indexOf(UserPermConstants.BASKA_BASVURU_GONDERME) > -1 || appData.basvuruYapan == userName
        ||  sharingType == "Düzenleme") {
        if (isEmpty(appData.temp) == true || appData.temp.includes("Görüntüleme") == false) {

            if (appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Taslak
                || appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Ucret_Bekleniyor
                || appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_GeriGonderildi) {
                gonder = <span id="GonderButton" className="text-danger"><button type="button" className="btn btn-success rounded btn-sm m-1" onClick={() => { submitApp(false) }}>
                    Gönder
                    <span class="ms-1 spinner-border spinner-border-sm" id="gonderbuttonSpinner" hidden></span>
                </button>
                    {/*{role.indexOf("TC$EIMZA$") > -1 && role.indexOf(UserPermConstants.EImzali_Gonderme) > -1 && < button type="button" className="btn btn-success  btn-sm " onClick={() => { submitApp(true) }}>*/}
                    {/*    E-İmza ile Gönder*/}
                    {/*    <span class="ms-1 spinner-border spinner-border-sm" id="eimzagonderbuttonSpinner" hidden></span>*/}

                    {/*</button>}*/}

                </span>
            }
        }
    }


    const getArchiveButton = () => {
        if (appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Ruhsat_Verildi || appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Onay_Verildi ||
            appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Iade_Edildi) {
            if (appData.archived != true && role.indexOf(UserPermConstants.Basvuru_Arsiv) != -1) { return <button className="btn btn-sm btn-primary rounded m-1" onClick={() => { appData.archived = true; saveApp(); }}>  Arşivle</button> }
        }
        if (appData.archived == true && role.indexOf(UserPermConstants.Basvuru_Arsiv_Cikar) != -1)  return <button className="btn btn-sm btn-primary rounded m-1" onClick={() => { appData.archived = false; saveApp(); }}>  Arşivden Çıkar</button>
        return "";
    }




    return <div className="row p-2 pb-5" style={{ "background-color": "#ebebeb" }} >

        <div className="row mb-2 p-0" >
            <div className="col-12 col-5 col-md-5 text-danger fw-bold">
                {appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Duzeltme_Bekleniyor &&
                    "Başvuru, Düzeltme Bekleniyor aşamasındadır. Düzeltmeler tamamlandıktan sonra ilgili kontrol panelinden “DÜZELTMEYİ GÖNDER” tuşuna basılması gerekmektedir. "
                }
                {appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Iade_Edildi ? ("İade Nedeni: "+appData.iadeNotu):""
                    
                }
            </div>
            <div className="col-12  col-md-7 align-items-end justify-content-end  text-end">

                <div class="btn-group flex-wrap" data-toggle="buttons" role="group">




                    <button className="btn btn-sm btn-primary rounded m-1" onClick={() => { changeComponent(backPage != null ? backPage : <Home changeComponent={props.changeComponent} />) }}> <i className="fa fa-arrow-circle-left pe-1"></i> Geri Don</button>


                    {getArchiveButton()}
                    {appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Taslak && appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Taslak &&apptypeList!=null&&
                        <button className="btn btn-sm btn-primary rounded m-1 ps-3 pe-3" onClick={() => { changeComponent(<ApplicationPrint rec={appData} changeComponent={changeComponent} backButton={props.backButton} appTypes={apptypeList} />) }}> <i className="fa fa-print pe-1"></i> Çıktı</button>}


                    {gonder}


                    <button className="btn btn-sm btn-primary rounded m-1" onClick={() => { changeComponent(<Sharing rec={appData} changeComponent={changeComponent} backButton={props.backButton} />) }}> <i className="fa fa-share-alt pe-1"></i> Paylaş</button>

                    <div class="form-check form-switch  text-primary pt-1 pe-3">
                        Takip Et<input class="form-check-input" type="checkbox" id="mySwitch" name="darkmode" value="yes" defaultChecked={following} onClick={handleFollowingChange} />
                    </div>
                </div>
            </div>
        </div>
        <div className="row" >

            <div className="col-md-3 col-12" id="buttonsbox">

                <div className="bg-white rounded-3 p-3 " >
                    <div>
                        <div className="fw-bold text-black text-center mb-1"> {appData.ada}-{appData.arsaSahibi} <span className="ps-3 text-muted fw-bold text-end"> {appData.id} </span></div>
                    </div>
                    <div>
                        {onmobile == true && <div className="text-black text-center mb-3"> {appData.basvuruTipi}</div>}
                    </div>
                    <div className="mt-2">
                        <button type="button" className={"btn fw-bold  btn-sm  text-decoration-none text-left " + (props.targetEimza == null ? " btn-primary":"text-muted")} onClick={(e) => { makeBold(e); setActivePanel(<AppMainData key={appData} appData={appData} apptypeList={apptypeList} denetimlist={denetimpaenls} changeComponent={changeComponent} setsabitsorulistData={setsabitsorulistData} refreshParent={() => { getmypermissionsonproject(); getApp(); }} />) }}>
                            Başvuru Bilgileri
                        </button>

                    </div>
                    <div className="mt-2">
                        <button type="button" className="btn fw-bold  btn-sm text-muted text-decoration-none " onClick={(e) => { makeBold(e); setActivePanel(<NotePanel appData={appData} />) }}>
                            Notlar
                        </button>

                    </div>
                    <div className="mt-2">
                        <button type="button" className="btn fw-bold btn-sm text-muted text-decoration-none " onClick={(e) => { makeBold(e); setActivePanel(<EventPanel appData={appData} />) }}>
                            Geçmiş Aktiviteler
                        </button>
                    </div>
                    <div className="mt-2">
                        <button type="button" className="btn fw-bold  btn-sm text-muted text-decoration-none " onClick={(e) => { makeBold(e); setActivePanel(<ApplicationAuthorPanel appData={appData} setActivePanel={setActivePanel} />) }}>
                            Müellif Listesi
                        </button>
                    </div>

                    <div className="mt-2">
                        <button type="button" className="btn fw-bold  btn-sm text-muted text-decoration-none " onClick={(e) => { makeBold(e); setActivePanel(<FilesPanel appData={appData} showAll={true} setActivePanel={setActivePanel} changeComponent={changeComponent}/>) }}>
                            Dosyalar
                        </button>
                    </div>


                    <div className="mt-2">
                        <button type="button" className={"btn fw-bold  btn-sm  text-decoration-none " + (props.targetEimza != null ? " btn-primary" : "text-muted")} id="EimzaButton" onClick={(e) => { makeBold(e); setActivePanel(<EImzaList appData={appData} setActivePanel={setActivePanel} changeComponent={changeComponent} />) }}>
                            E-İmza
                        </button>
                    </div>

                    {role.indexOf(UserPermConstants.MAKS_Veri_Girisi) >= 0 && <div className="mt-2 mb-5">
                        <button type="button" className="btn fw-bold  btn-sm text-muted text-decoration-none " id="MaksButton" onClick={(e) => { makeBold(e); setActivePanel(<MaksList appData={appData} setActivePanel={setActivePanel} />) }}>
                            MAKS
                        </button>
                    </div>
                    }


                    <div className='row mt-4 mb-5 pb-3 border-top' hidden={relatedapps.length == 0 || appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Taslak || role.indexOf(UserPermConstants.Ilgili_Basvurulari_Gorme) == -1}>
                        <div className="col-12 ps-2 pt-1">
                            <label htmlFor="relatedapps" className="form-label">İlgili Başvurular</label>
                            <div >
                                <select className="form-select form-select-sm" id="relatedapps" name="relatedapps" onChange={handleRelatedAppSelect} >
                                    {/* <option value={-1}>{appData.basvuruTipi + " (" + appData.id + ")"}</option>*/}
                                    {relatedapps.map((r, idx) => <option value={idx} selected={r.id == appData.id}>{r.basvuruTipi + " (" + r.id + ")"}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="border-top" >
                        {operationPanel}
                    </div>


                    <div className='row mt-5 mb-5 pb-4'>
                        {denetimpaenls.map(rec => <div key={ rec.id} className='row mt-4 border-top pt-1'>
                            <div className="col-12 text-center">
                                {getControllorDurumText(rec)}
                            </div>
                            <div className="col-12 ps-1">
                                <div className="text-dark  small">
                                    {rec.kname} {rec.kfamily }
                                </div>
                                <button type="button" className={getControllorColor(rec) + " btn fw-bold btn-link btn-sm  text-decoration-none p-0"}
                                    onClick={() => { makeBold(null); setActivePanel(<DenetimPaneli key={rec.id} rec={rec} appData={appData} mission={rec.Name} relatedapps={relatedapps}  apptypeList={apptypeList} refreshParent={() => { getmypermissionsonproject(); getApp(); }} denetimlist={denetimpaenls} changeComponent={props.changeComponent} />) }}>
                                    {rec.Name}
                                </button>

                            </div>
                        </div>)}
                    </div>
                </div>
            </div>


            <div className="col-md-9 col-12 rounded-3">

                <div className="bg-white rounded-3 p-2" >

                   

                    {activePanel != null ? activePanel :
                        props.targetEimza == null ?
                            <AppMainData key={appData} appData={appData} denetimlist={denetimpaenls} changeComponent={changeComponent} setsabitsorulistData={setsabitsorulistData} setData={setData} refreshParent={() => { getmypermissionsonproject(); getApp(); }} />
                            : < EImzaList appData={appData} targetEimza={props.targetEimza} setActivePanel={setActivePanel} changeComponent={changeComponent}/>}
                </div>


            </div> </div>
    </div>

}



