import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, isEmpty, sendpost, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import { typeOf } from 'react-is';
import BasvuruTipleriList from './BasvuruTipleriList.js';
import HavaleSablonSetting from './HavaleSablonSetting.js';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

export default function BasvuruTipleriDetail(props) {
    var postdata = {};
    var listData = props.basvuruTipleri;
    var appTypeGroups = props.appTypeGroups;
    const multiselectRef = React.createRef();
     const [basvuruTipi, setbasvuruTipi] = useState(props.basvuruTipi);
    const [basvuruTipiAdimlari, setBasvuruTipiAdimlari] = useState(props.basvuruTipiAdimlari.filter(x => x.basvuruTipi == basvuruTipi.id));
    const multiselectRefNew = React.createRef();

    const [showIsAdimlari, setShowIsAdimlari] = useState(false);


 





    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trim();
        const name = target.name;
        rec[name] = value;
        if ((name == "deadline" || name == "precondition") && value == '')
            rec[name] = null;
    }
    
    const addBasvuruTipiisAdimi = (item) => {
        var rec = {};

        rec.basvuruTipi = basvuruTipi.id;
        rec.isAdimi = item.id;
        sendpost("savebasvurutipisadimi", rec, "adresspiner", (response) => {
            response.json().then(value => {
                setBasvuruTipiAdimlari(value)
            })

        })
    }
    
    const removeBasvuruTipiisAdimi = (rec) => {
        var rec2 = basvuruTipiAdimlari.find(b => b.isAdimi == rec.id);
        if (rec2 == null) {
            showErrorMessage("İş Adımı Silinemedi");
            return;
        }
        sendpost("removebasvurutipisadimi", rec2.id, "adresspiner", (response) => {
            response.json().then(value => {
                setBasvuruTipiAdimlari(value)
            })

        })
    }


    const savedenetim = (rec) => {
        var r = rec;
        if (r == null) {
            postdata.hide = showIsAdimlari;
            r = postdata;
        }
        if (isEmpty(rec.name)) {
            showErrorMessage("Başvuru Tipi Adı Boş Olamaz !");
            return;
        }

        fetch("savedenetim", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(r)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                if (rec.id != null) {
                    showSuccessMessage("Başvuru Tipi Güncellendi. ")
                }
                else {
                    showSuccessMessage("Başvuru Tipi Eklendi ", "", () => {
                        response.json().then(value => {
                          setbasvuruTipi(value)
                        })
                    })
                }
                //else {
                  
                //    if (document.getElementById("DenetimAdi")) document.getElementById("DenetimAdi").value = "";
                //    if (document.getElementById("active")) document.getElementById("active").checked = false;
                //    if (document.getElementById("onaysureci")) document.getElementById("onaysureci").checked = false;
                //    if (document.getElementById("ruhsatsureci")) document.getElementById("ruhsatsureci").checked = false;
                //    if (document.getElementById("hide")) document.getElementById("hide").checked = false;
                //    if (document.getElementById("eksiksiz")) document.getElementById("eksiksiz").checked = false;
                //    if (document.getElementById("deadline")) document.getElementById("deadline").value = "";
                //    if (document.getElementById("duzeltmeDeadline")) document.getElementById("duzeltmeDeadline").value = "";
                //    if (document.getElementById("duzeltmeSayi")) document.getElementById("duzeltmeSayi").value = "";
                //    if (document.getElementById("hizliArsiv")) document.getElementById("hizliArsiv").checked = false;
                //    if (document.getElementById("grup")) document.getElementById("grup").selectedIndex = -1;
                //    if (multiselectRefNew.current) multiselectRefNew.current.resetSelectedValues();
                //    postdata = {};

                //}
            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (target.type === 'text' && isEmpty(value) == false)
            value = value.trim();

        postdata[target.name] = value;

        if (target.name == "deadline" && value == '')
            postdata[target.name] = null;

    }


    useEffect(() => {
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl)
        })
    }, [])



    return (
        <div className="p-2 rounded-3" style={{ "background-color": "#EDEDF7", "min-height": "100vh" }}>
            <div className="row " >
                <div className="col-12 col-md-6 " >

                    <span className="me-3" style={{ "font-size": "14px", "font-weight": "600", "color": "#4b5675" }}> {basvuruTipi.userName} </span>
                    <span className="me-3" style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}>{basvuruTipi.crerateTime} </span>
                    <span style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}> Id: {basvuruTipi.id} </span>

                </div>
                <div className="col-12 col-md-6 text-end  pt-1" >
                    <button onClick={() => {
                        savedenetim(basvuruTipi)
                    }
                    } className="btn btn-primary btn-sm text-decoration-none me-2" > Kaydet </button>
                    <button onClick={() => window.changeComponent(<BasvuruTipleriList />)} className="btn btn-secondary btn-sm text-decoration-none">Geri Dön</button >
                </div>
            </div>

            <div className="row mt-1 p-3" >
                <div className="col-12 col-md-6" >
                    <div className=" bg-white rounded-3" >
                        <div className="p-2 fw-bold border-bottom" >
                            Genel Özellikler

                        </div>
                        <div className="p-3 pt-2" >
                            Başvuru Tipi <span className="text-danger" >* </span>
                            <input type="text" className="form-control form-control-sm" pattern="[a-zA-Z0-9ÜüŞşÇçÖöĞğİı\(\)_\- ]+" placeholder="Başvuru Tipi" name="name" defaultValue={basvuruTipi.name} onChange={(e) => updateRecord(basvuruTipi, e)} />
                        </div>


                        <div className="p-3 pt-1" >
                        Başvuru Grubu
                        <select className="form-select form-select-sm" name="grup" required={true} onChange={(e) => updateRecord(basvuruTipi, e)}>
                            <option></option>
                            {appTypeGroups.map(x => <option value={x.id} selected={x.id == basvuruTipi.grup}>{x.name}</option>)}
                        </select>
                        </div>




                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" >  Aktif </label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="active" defaultChecked={basvuruTipi.active} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7  textvmuted small" style={{ "text-align": "justify" }}>
                                Başvurunun yayında olup olmayacagini belirler.
                            </div>
                        </div>

                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" > Gizli </label>
                            </div>
                            <div class="col-2" >
                                <input type="checkbox" className="form-check-input" name="hide" defaultChecked={basvuruTipi.hide} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7  textvmuted" style={{ "text-align": "justify" }}>

                            </div>
                        </div>
                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" > Zorunlu Dosya Kontrol </label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="eksiksiz" defaultChecked={basvuruTipi.eksiksiz} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7  textvmuted small" style={{ "text-align": "justify" }}>
                                Bu başvuruda zorunlu olarak isaretlediğiniz dosyaların yüklenmeden başvurunun gönderilmesini engeller.
                            </div>
                        </div>


                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" > Hızlı Arşiv </label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="hizliArsiv" defaultChecked={basvuruTipi.hizliArsiv} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7  textvmuted  small" style={{ "text-align": "justify" }}>
                                Başvuru, süreç tamamlandığında otomatik olarak arşive alınır. Aksi halde süreci tamamlanan başvuruda 15 gün boyunca herhangi bir işlem yapılmazsa arşive alınır.
                            </div>
                        </div>

                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" >  Onay Süreci </label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="onaysureci" defaultChecked={basvuruTipi.onaysureci} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7  textvmuted small" style={{ "text-align": "justify" }}>
                                Başvurunun kontrolü tamamlandıktan sonra yöneticinin onayına sunulmasını sağlar. Bunun için yöneticinin rolüne "Kullanıcı Yönetimi/Kullanıcı Rolleri" ekranından "Onay" yetkisi verilmelidir.
                            </div>
                        </div>


                        <div className="p-3 pt-1 row" >
                            <div class="col-4" >
                                <label class="form-check-label" for="inlineCheckbox1" >  Ruhsat Süreci </label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="ruhsatsureci" defaultChecked={basvuruTipi.ruhsatsureci} onChange={(e) => updateRecord(basvuruTipi, e)} />
                            </div>
                            <div class="col-7 textvmuted small" style={{ "text-align": "justify" }}>
                                Başvurunun ruhsat aşamasının olup olmayacağını belirler.
                            </div>
                        </div>
                    </div>
                </div>





                <div className="col-12 col-md-6" >
                    <div className="bg-white rounded-3" >
                        <div className="p-2 fw-bold border-bottom" >
                            Süreler ve Kıstaslar

                        </div>
                        <div className="row p-3 pt-2" >
                            <div className="col-3" >

                                Düzeltme Süre
                                <input type="number" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} placeholder="" name="duzeltmeDeadline" onChange={(e) => updateRecord(basvuruTipi, e)} defaultValue={basvuruTipi.duzeltmeDeadline} />

                            </div>
                            <div class="col-9  textvmuted small" style={{ "text-align":"justify"} }>
                                Kontrolörlerin istediği düzeltmeler için müellife verilen süredir. Bu süre zarfında düzeltme yapılıpmaz ise "düzeltmeyi gönder" butonuo pasif olur ve başvuru "İade Listesi" ne düşer. Kurum içindeki yetkili kullanıcı kendisi isterse "düzeltmeyi gönder" butonuna basarak başvuruyu müellif adına belediyeye gönderebilir.
                            </div>

                        </div>


                        <div className="row p-2 pt-2" >
                            < div className="col-3" >
                                Düzeltme Sayı
                                <input type="number" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} placeholder="" name="duzeltmeSayi" onChange={(e) => updateRecord(basvuruTipi, e)} defaultValue={basvuruTipi.duzeltmeSayi} />
                            </div>
                            <div class="col-9  textvmuted small" style={{ "text-align": "justify" }}>
                                Kontrolörlerin isteyeceği düzeltmelere bir sayı sınırı koyar. Bu sınır geçtiğinde "düzeltmeyi gönder" butonu pasif olur ve başvuru "İade Listesi" ne düşer. Kurum içindeki yetkili kullanıcı kendisi isterse "düzeltmeyi gönder" butonuna basarak başvuruyu müellif adına belediyeye gönderebilir.
                            </div>
                        </div>

                        <div className="row p-2 pt-2" >
                            <div className="col-3" >
                                Kontrol Süre
                                <input type="number" className="form-control form-control-sm  " placeholder="" name="deadline" onChange={(e) => updateRecord(basvuruTipi, e)} defaultValue={basvuruTipi.deadline} />

                            </div>
                            <div class="col-9  textvmuted small" style={{ "text-align": "justify" }}>
                                Kontrollerini tamamlamaları için Kontrolörlere verilen süredir. Bu sürede kontrol tamamlanmazsa geciken başvurular bölümüne düşer. Verilen süre kontrolör ekranında, yetkisi olan kullanıcılara geri sayım olarak görünür. Belirtilen süre İŞ GÜNÜDÜR. Hafta sonları dahil değildir.
                            </div>
                        </div>

                        < div className="p-2 pt-2" >
                            Koşul
                            <Multiselect className="p-0 ps-1 form-control-sm"
                                options={listData.filter(x => x.isadimi != true)}
                                emptyRecordMsg="Başka Kayıt Yok"
                                ref={multiselectRef}
                                displayValue="name"
                                isObject={true}
                                onSelect={(selectedList, removedItem) => basvuruTipi["precondition"] = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join()} // Function will trigger on select event
                                onRemove={(selectedList, removedItem) => basvuruTipi["precondition"] = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join()} // Function will trigger on remove event
                                selectedValues={basvuruTipi.precondition == null ? [] : listData.filter(x => x.isadimi != true&& basvuruTipi.precondition.split(",").includes(x.id + ""))}
                            />
                            <div class="p-0 textvmuted small" style={{ "text-align": "justify" }}>
                            Başvurunun yapılabilmesi için seçilecek diğer başvuruların sürecinin tamamlanmasını gerektirir.
                        </div>
                        </div>

                      

                    </div>

                    <div className="bg-white rounded-3 mt-3" >
                        <div className="p-2 fw-bold border-bottom" >
                            İş Adımları
                            <span className="spinner-border spinner-border-sm ms-3" id="adresspiner" hidden>   </span>
                        </div>
                        {basvuruTipi.id == null && <div className="text-muted ps-2">İş Adımlarını seçmek için önce Başvuru Tipini Kaydediniz </div>}
                        

                            < div className="p-2" >
                                <Multiselect className="p-0 ps-1 form-control-sm"
                                    options={listData.filter(x => x.isadimi == true)}
                                    emptyRecordMsg="Başka Kayıt Yok"
                                    ref={multiselectRef}
                                displayValue="name"
                                disable={basvuruTipi.id == null }
                                    isObject={true}
                                    onSelect={(selectedList, item) => addBasvuruTipiisAdimi(item)} // Function will trigger on select event
                                    onRemove={(selectedList, item) => removeBasvuruTipiisAdimi(item)} // Function will trigger on remove event
                                    selectedValues={listData.filter(x => x.isadimi == true && basvuruTipiAdimlari.some(b => b.isAdimi == x.id))}
                                />
                            </div>
                    </div>
                </div>
            </div>
            {basvuruTipi.id != null ? <HavaleSablonSetting disabled basvuruTipi={basvuruTipi} isAdimlari={basvuruTipiAdimlari} key={basvuruTipiAdimlari} /> : <span className="text-muted p-3">Havale Şablonları eklemek için önce Başvuru Tipini Kaydediniz </span>}
            
        </div>

    );
}